import { Tag, TagWithTagMappings } from "types/Content";
import fetchn from "utils/Fetchn";

const HomeTagApi = (signal?) => {
  return {
    getTags: async () => {
      const { data } = await fetchn.request<Tag[]>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        params: {
          type: "MANAGED",
        },
        signal,
      });
      return data;
    },

    getTagWithBooks: async (tagId: number) => {
      const { data } = await fetchn.request<TagWithTagMappings>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/${tagId}`,
        signal,
      });
      return data;
    },

    addTag: async (name: string) => {
      await fetchn.request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        data: {
          name,
          type: "MANAGED",
          filter: "KOREAN",
          bookIds: [],
        },
        signal,
      });
    },

    modifyTag: async (
      tagId: number,
      title: string,
      filter: string,
      imageurl: string,
      enabled: boolean
    ) => {
      await fetchn.request({
        method: "PATCH",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/${tagId}`,
        data: {
          name: title,
          filter: filter == "ALL" ? "" : filter,
          imageUrl: imageurl,
          enabled: enabled,
        },
        signal,
      });
    },

    deleteTag: async (tagId: number) => {
      await fetchn.request({
        method: "DELETE",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/${tagId}`,
        signal,
      });
    },
    updateTagMappings: async (tagId: number, bookIds: number[]) => {
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/tags/${tagId}/mappings/reset`,
        data: { bookIds },
        signal,
      });
    },
  };
};

export default HomeTagApi;
