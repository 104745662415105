import StorageKey from "constants/StorageKey";

const getItem = (key: StorageKey) => {
  const data = localStorage.getItem(key);
  return JSON.parse(data);
};

const setItem = (key: StorageKey, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const removeItem = (key: StorageKey) => {
  localStorage.removeItem(key);
};

const Storage = { getItem, setItem, removeItem };

export default Storage;
