import { useQuery } from "react-query";

import QueryKey from "constants/QueryKey";

import { useCallback } from "react";
import CategoryApi from "apis/Category";
import { RemoteClassCategory, RemoteSubjectCategory } from "types/Content";

const useCategory = () => {
  const { data: classCategories = [] } = useQuery(
    QueryKey.classCategories,
    CategoryApi().getClassCategories,
    {
      cacheTime: Infinity,
      staleTime: 1000 * 60 * 10,
    }
  );

  const { data: subjectCategories = [] } = useQuery(
    QueryKey.subjectCategories,
    CategoryApi().getSubjectCategories,
    {
      cacheTime: Infinity,
      staleTime: 1000 * 60 * 10,
    }
  );

  const getClassCategory = useCallback(
    (id: string) => classCategories.find((category) => category.id === id),
    [classCategories]
  );
  const getTopClassCategory = useCallback(
    (id: string): RemoteClassCategory => {
      const category = getClassCategory(id);
      return category?.parentId
        ? getTopClassCategory(category.parentId)
        : category;
    },
    [getClassCategory]
  );
  const getClassCategoriesWithParents = (
    classCategoryId: string
  ): RemoteClassCategory[] => {
    const category = getClassCategory(classCategoryId);
    return category
      ? [...getClassCategoriesWithParents(category.parentId), category]
      : [];
  };

  const getSubjectCategory = useCallback(
    (id: string) => subjectCategories.find((category) => category.id === id),
    [subjectCategories]
  );
  const getSubjectCategoriesWithParents = useCallback(
    (subjectCategoryId: string): RemoteSubjectCategory[] => {
      const category = getSubjectCategory(subjectCategoryId);
      return category
        ? [...getSubjectCategoriesWithParents(category.parentId), category]
        : [];
    },
    [getSubjectCategory]
  );

  const getChildClassCategories = useCallback(
    (ids: string[]) =>
      classCategories.filter((category) => ids?.includes(category.parentId)),
    [classCategories]
  );
  const getLeafClassCategories = useCallback(
    (ids: string[]): RemoteClassCategory[] =>
      ids?.reduce((values, id) => {
        const categories = classCategories.filter(
          (category) => category.parentId === id
        );
        const leafs =
          categories?.length > 0
            ? getLeafClassCategories(categories.map((category) => category.id))
            : getClassCategory(id)
            ? [getClassCategory(id)]
            : [];
        return [...values, ...leafs];
      }, []),
    [classCategories, getClassCategory]
  );

  return {
    classCategories,
    subjectCategories,
    getClassCategory,
    getLeafClassCategories,
    getChildClassCategories,
    getTopClassCategory,
    getSubjectCategory,
    getSubjectCategoriesWithParents,
    getClassCategoriesWithParents,
  };
};

export default useCategory;
