import {
  DeliveryMethod,
  DepositAction,
  LibraryMemoType,
  LibraryStatus,
  LibraryType,
  PointAction,
} from "constants/Server";
import { InfiniteList } from "types/Common";
import { RemoteCartItem, RemoteLibraryMemo } from "types/Content";
import {
  CartLibrary,
  CertificationHistory,
  CertificationLogEntity,
  RecommendRewardManagement,
  RecommendRewardManagementDto,
  RemoteAddress,
  RemoteCoordinates,
  RemoteLibraryDeposit,
  RemoteLibraryPaymentHistory,
  RemoteLibraryPoint,
  RemoteLibraryWithProfileInfo,
  RemoteNotification,
} from "types/Library";
import fetchn from "utils/Fetchn";

const LibraryApi = (signal?) => {
  return {
    getLibrary: async (libraryId: number) => {
      // GET /manager/v0/libraries/{libraryId}
      const { data } = await fetchn.request<RemoteLibraryWithProfileInfo>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}`,
        signal,
      });
      return data;
    },
    getLibraries: async ({
      page,
      size,
      libraryName,
      phoneNumber,
      libraryTypes,
      deleted,
    }: {
      page: number;
      size: number;
      libraryName: string;
      phoneNumber: string;
      libraryTypes: LibraryType[];
      deleted: boolean;
    }) => {
      // GET /manager/v0/libraries/search
      const { data } = await fetchn.request<
        InfiniteList<RemoteLibraryWithProfileInfo>
      >({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/libraries/search`,
        params: {
          libraryName,
          "page.page": page,
          "page.size": size,
          phoneNumber,
          types: libraryTypes,
          deleted,
          sort: "REGISTRATION",
        },
        signal,
      });
      return data;
    },
    editLibrary: async ({
      libraryId,
      address,
      coordinates,
      name,
      status,
      type,
      phoneNumber,
      typeValidTo,
    }: {
      libraryId: number;
      address?: RemoteAddress;
      brandImageUrl?: string;
      brandLink?: string;
      coordinates?: RemoteCoordinates;
      imageUrl?: string;
      name?: string;
      status?: LibraryStatus;
      type?: LibraryType;
      phoneNumber?: string;
      typeValidTo?: string;
    }) => {
      // PATCH /manager/v0/libraries/{libraryId}
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}`,
        data: {
          address,
          coordinates,
          name,
          status,
          type,
          phoneNumber,
          typeValidTo: type == LibraryType.READERS_CLUB ? typeValidTo : null,
        },
        signal,
      });
    },
    editLibraryName: async (libraryId: number, name: string) => {
      // POST /manager/v0/libraries/${libraryId}/name
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/name`,
        data: { name },
        signal,
      });
    },
    getPoints: async ({
      libraryId,
      page,
      size,
    }: {
      libraryId: number;
      page: number;
      size: number;
    }) => {
      // GET /manager/v0/libraries/{libraryId}/points/search
      const { data } = await fetchn.request<InfiniteList<RemoteLibraryPoint>>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/points/search`,
        params: {
          "page.cursor": page ? page - 1 : page,
          "page.size": size,
        },
        signal,
      });
      return data;
    },
    getPointTotal: async (libraryId: number) => {
      // GET /manager/v0/libraries/{libraryId}/points/total
      const { data } = await fetchn.request<{ amount: number }>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/points/total`,
        signal,
      });
      return data;
    },
    addPoint: async ({
      libraryId,
      amount,
      adminComment,
      validTo,
    }: {
      libraryId: number;
      amount: number;
      adminComment: string;
      validTo: string;
    }) => {
      // POST /manager/v0/libraries/{libraryId}/points
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/points`,
        signal,
        data: {
          action: PointAction.ACCUMULATE_BY_ADMIN,
          amount,
          adminComment,
          validTo,
        },
      });
    },
    deletePoint: async ({
      libraryId,
      pointId,
      message,
    }: {
      libraryId: number;
      pointId: number;
      message: string;
    }) => {
      // DELETE /manager/v0/libraries/{libraryId}/points/{pointId}
      await fetchn.request({
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/points/${pointId}`,
        signal,
        data: {
          adminComment: message,
        },
      });
    },
    deductPoint: async ({
      libraryId,
      adminComment,
      amount,
    }: {
      libraryId: number;
      adminComment: string;
      amount: number;
    }) => {
      // POST /manager/v0/libraries/{libraryId}/points/deduct
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/points/deduct`,
        data: {
          action: PointAction.ADMIN_DEDUCTION,
          adminComment,
          amount,
        },
      });
    },
    getDeposits: async ({
      libraryId,
      page,
      size,
    }: {
      libraryId: number;
      page: number;
      size: number;
    }) => {
      // GET /manager/v0/libraries/{libraryId}/deposits/search
      const { data } = await fetchn.request<InfiniteList<RemoteLibraryDeposit>>(
        {
          method: "POST",
          url:
            process.env.REACT_APP_SERVER_URI +
            `/manager/v0/libraries/${libraryId}/deposits/search`,
          params: {
            "page.cursor": page ? page - 1 : page,
            "page.size": size,
          },
          signal,
        }
      );
      return data;
    },
    getDepositTotal: async (libraryId: number) => {
      // GET /manager/v0/libraries/{libraryId}/deposits/total
      const { data } = await fetchn.request<{ amount: number }>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/deposits/total`,
        signal,
      });
      return data;
    },
    addDeposit: async ({
      libraryId,
      amount,
      adminComment,
    }: {
      libraryId: number;
      amount: number;
      adminComment: string;
    }) => {
      // POST /manager/v0/libraries/{libraryId}/deposits
      await fetchn.request<{ amount: number }>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/deposits`,
        signal,
        data: {
          action: DepositAction.ADMIN_ACCUMULATION,
          amount,
          adminComment,
        },
      });
    },
    deleteDeposit: async ({
      libraryId,
      depositId,
    }: {
      libraryId: number;
      depositId: number;
    }) => {
      // DELETE /manager/v0/libraries/{libraryId}/points/{pointId}
      await fetchn.request({
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/deposits/${depositId}`,
        signal,
      });
    },
    deductDeposit: async ({
      libraryId,
      amount,
      adminComment,
    }: {
      libraryId: number;
      amount: number;
      adminComment: string;
    }) => {
      // POST /manager/v0/libraries/{libraryId}/deposits
      await fetchn.request<{ amount: number }>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/deposits/deduct`,
        signal,
        data: {
          action: DepositAction.ADMIN_DEDUCTION,
          amount,
          adminComment,
        },
      });
    },
    getPayments: async ({
      libraryId,
      page,
      size,
    }: {
      libraryId: number;
      page?: number;
      size?: number;
    }) => {
      // GET /manager/v0/libraries/{libraryId}/payment-history
      const { data } = await fetchn.request<
        InfiniteList<RemoteLibraryPaymentHistory>
      >({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/payment-history`,
        params: {
          "page.cursor": page,
          "page.size": size,
        },
        signal,
      });
      return data;
    },
    getLibraryNames: async (libraryName: string) => {
      // GET /operator/v0/libraries/search/name
      const { data } = await fetchn.request<
        Array<{ id: number; name: string }>
      >({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/search/name`,
        params: {
          "page.size": 30,
          name: libraryName,
        },
        signal,
      });
      return data;
    },
    getNotifications: async ({
      libraryId,
      page,
      size,
    }: {
      libraryId: number;
      page?: number;
      size?: number;
    }) => {
      // GET /manager/v0/libraries/{libraryId}/notifications
      const { data } = await fetchn.request<InfiniteList<RemoteNotification>>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/notifications`,
        params: {
          page,
          size,
        },
        signal,
      });
      return data;
    },
    getCartItems: async ({
      libraryId,
      deliveryMethod,
    }: {
      libraryId: number;
      deliveryMethod: DeliveryMethod;
    }) => {
      // GET /manager/v0/libraries/{libraryId}/cart
      const { data } = await fetchn.request<RemoteCartItem[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/cart`,
        params: {
          delivery_method: deliveryMethod,
        },
        signal,
      });
      return data;
    },
    deleteLibrary: async (libraryId: number) => {
      // DELETE /manager/v0/libraries/{libraryId}/withdraw
      const { data } = await fetchn.request({
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/withdraw`,
        signal,
      });
      return data;
    },
    getCertificationLogs: async (libraryId: number) => {
      // GET /manager/v0/libraries/{libraryId}/certification-log
      const { data } = await fetchn.request<CertificationLogEntity[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/certification-log`,
      });
      return data;
    },
    editSnsId: async (libraryId: number, logId: number) => {
      // PATCH /manager/v0/libraries/{libraryId}/certification-log/{logId}
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/certification-log/${logId}`,
      });
    },
    getCartRecentRegisterLibraries: async () => {
      // GET /manager/v0/libraries/cart/recent-register
      const { data } = await fetchn.request<CartLibrary[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/cart/recent-register`,
        signal,
      });
      return data;
    },
    getMemos: async (libraryId: number) => {
      // GET /manager/v0/libraries/{libraryId}/memos
      const { data } = await fetchn.request<RemoteLibraryMemo[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/memos`,
        signal,
      });
      return data;
    },
    getMemo: async (libraryId: number, memoId: number) => {
      // GET /manager/v0/libraries/{libraryId}/memos/{memoId}
      const { data } = await fetchn.request<RemoteLibraryMemo>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/memos/${memoId}`,
        signal,
      });
      return data;
    },
    addMemos: async ({
      libraryId,
      message,
      type,
      imageUrls,
    }: {
      libraryId: number;
      message: string;
      type: LibraryMemoType;
      imageUrls?: string[];
    }) => {
      // POST /manager/v0/libraries/{libraryId}/memos
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/memos`,
        data: {
          message,
          type,
          imageUrls,
        },
        signal,
      });
    },
    modifyMemos: async ({
      libraryId,
      memoId,
      message,
      type,
      imageUrls,
    }: {
      libraryId: number;
      memoId: number;
      message: string;
      type: LibraryMemoType;
      imageUrls?: string[];
    }) => {
      // PATCH /manager/v0/libraries/{libraryId}/memos/{memoId}
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/memos/${memoId}`,
        data: {
          message,
          type,
          imageUrls,
        },
        signal,
      });
    },
    deleteMemo: async (libraryId: number, memoId: number) => {
      // DELETE /manager/v0/libraries/{libraryId}/memos/{memoId}
      await fetchn.request({
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/memos/${memoId}`,
        signal,
      });
    },
    getRecommendRewardManagement: async (libraryId: number) => {
      // GET /manager/v0/libraries/{libraryId}/recommends/reward/management
      const { data } = await fetchn.request<RecommendRewardManagementDto>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/recommends/reward/management`,
        signal,
      });
      return data;
    },
    applyRecommendRewardManagement: async ({
      libraryId,
      recommendRewardManagement,
    }: {
      libraryId: number;
      recommendRewardManagement: RecommendRewardManagement;
    }) => {
      // POST /manager/v0/libraries/{libraryId}/deposits
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/libraries/${libraryId}/recommends/reward/management`,
        signal,
        data: recommendRewardManagement,
      });
    },
    getCertificationHistoryList: async (phoneNumber: string) => {
      // GET /manager/v0/certification/history
      const { data } = await fetchn.request<CertificationHistory[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/certification/history`,
        signal,
        params: { phoneNumber },
      });
      return data;
    },
    addLibrarySegmentTags: async (
      values: Array<{
        libraryId: number;
        tag: string;
      }>
    ) => {
      // POST /manager/v0/stocks/bulk-convert-rental-fake-stocks
      await fetchn.request<string[]>({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/segment-tags`,
        data: values,
        signal,
      });
    },
  };
};

export default LibraryApi;
