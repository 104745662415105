import TagApi from "apis/Tag";
import QueryKey from "constants/QueryKey";
import { useQuery } from "react-query";

const useEventsTag = (type: string) => {
  // @ts-ignore
  const { data: tags = [], isFetching } = useQuery(
    QueryKey.eventtags,
    () => TagApi().getEventTags(type),
    {
      // cacheTime: Infinity,
      // staleTime: 1000 * 60 * 10
    }
  );
  return {
    tags: tags.map((info, index) => {
      info.idx = index;
      return info;
    }),
    isFetching,
  };
};

export default useEventsTag;
