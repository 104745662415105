import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";

import QueryKey from "constants/QueryKey";
import StockApi from "apis/Stock";
import { Controller, useForm } from "react-hook-form";

const StockSellableAddModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<{
    bookId: number;
    quantity: number;
    woodoSectionCode: string;
    payback: boolean;
  }>({
    mode: "onChange",
  });

  const { mutate, isLoading } = useMutation(StockApi().addStockSellable, {
    onSuccess: () => {
      reset();
      queryClient.invalidateQueries(QueryKey.stocksPaging);
      onClose();
    },
  });

  const onSubmit = handleSubmit((values) => {
    mutate(values);
  });

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">우도샵 재고 등록</DialogTitle>
        <DialogContent>
          <Controller
            name="bookId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                label="Book Id"
                margin="dense"
                name="amount"
                type="number"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Controller
            name="quantity"
            control={control}
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                label="수량"
                margin="dense"
                name="amount"
                type="number"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="woodoSectionCode"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                label="서가번호"
                margin="dense"
                name="woodoSectionCode"
                type="string"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="payback"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    color="primary"
                    name="processed"
                    onChange={(e) => onChange(e.target.checked)}
                    onBlur={onBlur}
                  />
                }
                label="페이백"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <LoadingButton
            onClick={onSubmit}
            color="primary"
            variant="contained"
            loading={isLoading}
            disabled={!isValid}
          >
            등록
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StockSellableAddModal;
