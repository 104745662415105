import { Auth } from "types/Account";
import fetchn from "utils/Fetchn";

const AuthApi = (signal?) => {
  return {
    signIn: async (idToken: string) => {
      // POST /v0/accounts/signin
      const { data } = await fetchn.request<Auth>({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/v0/accounts/signin`,
        data: {
          idToken,
        },
        signal,
      });
      return data;
    },
    refreshToken: async () => {
      // POST /v0/accounts/token/refresh
      const { data } = await fetchn.request<Auth>({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/v0/accounts/token/refresh`,
        signal,
      });
      return data;
    },
  };
};

export default AuthApi;
