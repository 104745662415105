import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as XLSX from "xlsx";

import TagApi from "apis/Tag";
import QueryKey from "constants/QueryKey";
import { DataGrid } from "components";
import { TableColumn } from "react-data-table-component";
import { TagMapping } from "types/Content";
import dayjs from "dayjs";
import CommonApi from "../../../apis/Common";
import { ImageType, Platform, TagFilter } from "../../../constants/Server";

const parseParams = (params) => {
  return {
    tagId: parseInt(params.tagId),
  };
};

const TagCustomEdit = () => {
  const imageInputRef = useRef<HTMLInputElement | null>();
  const imageUploadRef = useRef<HTMLInputElement | null>();
  const webImageUploadRef = useRef<HTMLInputElement | null>();

  const queryClient = useQueryClient();
  const params = useParams();
  const paramsParsed = useMemo(() => parseParams(params), [params]);
  const navigate = useNavigate();

  const columns: TableColumn<TagMapping>[] = useMemo(
    () => [
      {
        name: "Book ID",
        selector: (row) => row.bookId,
        width: "100px",
      },
      {
        name: "제목",
        selector: (row) => row.title,
        wrap: true,
      },
      {
        name: "ISBN",
        selector: (row) => row.isbn,
        wrap: true,
      },
    ],
    []
  );

  const { data, isFetching } = useQuery(
    [QueryKey.tag, { tagId: paramsParsed.tagId }],
    () => TagApi().getTagWithBooks(paramsParsed.tagId)
  );

  const { mutate: deleteTag, isLoading: isDeleting } = useMutation(
    () => TagApi().deleteTag(paramsParsed.tagId),
    {
      onSuccess: () => {
        toast.success("삭제되었습니다.");
        queryClient.invalidateQueries(QueryKey.customtags);
        navigate(-1);
      },
    }
  );

  const { mutate: updateTagMappings, isLoading: isUpdating } = useMutation(
    (bookIds: number[]) =>
      TagApi().updateTagMappings(paramsParsed.tagId, bookIds),
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        queryClient.invalidateQueries([
          QueryKey.tag,
          { tagId: paramsParsed.tagId },
        ]);
      },
    }
  );

  const handleUpdateTagMappings = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: "binary", cellDates: true });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const json: any[] = XLSX.utils.sheet_to_json(ws, {
        header: "A",
        blankrows: false,
        raw: true,
        defval: "",
        range: 1,
      });
      updateTagMappings(json.map((data) => data.A));
    };
    reader.readAsBinaryString(file);
  };

  const handleDeleteTag = () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      deleteTag();
    }
  };

  const handlePressExport = () => {
    const ws = XLSX.utils.json_to_sheet(
      data.tagMappings.map((tagMapping) => ({
        "Book ID": tagMapping.bookId,
        도서명: tagMapping.title,
        ISBN: tagMapping.isbn,
        이미지: tagMapping.imageUrl,
      })),
      {
        header: ["Book ID", "도서명", "ISBN", "이미지"],
      }
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(
      wb,
      dayjs().format(`태그도서목록_${data.name}_YYYYMMDDHHmm`) + ".xlsx"
    );
  };

  const { mutate: uploadImage, isLoading: isUploading } = useMutation(
    ({ image, platform }: { image: File; platform: Platform }) =>
      CommonApi().uploadImage({ image, imageType: ImageType.ETC }),
    {
      onSuccess: (data, { platform }) => {
        if (platform == Platform.APP) {
          setImageUrl(data.url);
        } else if (platform == Platform.WEB) {
          setWebImageUrl(data.url);
        }
      },
    }
  );

  const handleChangeImage = (image, platform: Platform) => {
    if (image) {
      uploadImage({ image, platform });
    }
  };

  const { mutate: modify, isLoading: isModify } = useMutation(
    ({
      title,
      filter,
      imageUrl,
      webImageUrl,
      enabled,
      metaDescription,
      description,
    }: {
      title: string;
      filter: string;
      imageUrl: string;
      webImageUrl: string;
      enabled: boolean;
      metaDescription: string;
      description: string;
    }) =>
      TagApi().modifyTag(
        paramsParsed.tagId,
        title,
        filter,
        imageUrl,
        webImageUrl,
        enabled,
        metaDescription,
        description
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.customtags);
        navigate(-1);
      },
    }
  );

  const handleModify = () => {
    if (window.confirm("정말 수정하시겠습니까?")) {
      const info = {
        title: tagName,
        filter: filter,
        imageUrl,
        webImageUrl,
        enabled,
        metaDescription,
        description,
      };
      modify(info);
    }
  };

  const [tagName, setTagName] = useState("");
  const [filter, setFilter] = useState("");
  const [description, setDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [webImageUrl, setWebImageUrl] = useState("");
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (data != null) {
      setTagName(data.name);
      setFilter(data.filter);
      setImageUrl(data.imageUrl);
      setWebImageUrl(data.webImageUrl);
      setEnabled(data.enabled);
      setMetaDescription(data.metaDescription);
      setDescription(data.description);

      console.log(data.filter);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{"Custom 태그 상세"}</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {"Custom 태그 상세"}
              </Typography>
            </Grid>

            <Grid item>
              <Typography color="textPrimary">{"활성화 여부"}</Typography>
              <Switch
                checked={enabled}
                color="primary"
                edge="start"
                name="enabled"
                onChange={(e) => {
                  setEnabled(e.target.checked);
                }}
                sx={{ mr: 10 }}
              />

              <Button
                key="test"
                color="primary"
                sx={{ mr: 1 }}
                variant="contained"
                onClick={handlePressExport}
                disabled={!(data?.tagMappings?.length > 0)}
              >
                엑셀 출력
              </Button>
              <LoadingButton
                variant="contained"
                loading={isUpdating}
                onClick={() => imageInputRef.current.click()}
                sx={{ mr: 1 }}
              >
                도서 목록 수정
              </LoadingButton>
              <LoadingButton
                color="warning"
                disabled={[1, 2, 3].includes(paramsParsed.tagId)}
                variant="contained"
                loading={isModify}
                sx={{ mr: 1 }}
                onClick={handleModify}
              >
                수정
              </LoadingButton>

              <LoadingButton
                color="error"
                disabled={[1, 2, 3].includes(paramsParsed.tagId)}
                variant="contained"
                loading={isDeleting}
                onClick={handleDeleteTag}
              >
                태그 삭제
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={6}>
              <Typography color="textPrimary">{"태그 명"}</Typography>
              <TextField
                id="standard-basic"
                fullWidth
                name="link"
                onChange={(e) => {
                  setTagName(e.target.value);
                }}
                value={tagName ? tagName : ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary">{"노출 태그 타입"}</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <MenuItem value={TagFilter.ALL}>모두</MenuItem>
                  <MenuItem value={TagFilter.KOREAN}>한글</MenuItem>
                  <MenuItem value={TagFilter.ENGLISH}>영어</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary">{"노출 설명"}</Typography>
            <TextField
              fullWidth
              name="description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description ? description : ""}
              variant="outlined"
              multiline
              minRows={3}
              maxRows={5}
            />
          </Grid>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={12}>
              <Typography color="textPrimary">{"메타 정보 - 설명"}</Typography>
              <TextField
                fullWidth
                name="metaDescription"
                onChange={(e) => {
                  setMetaDescription(e.target.value);
                }}
                value={metaDescription ? metaDescription : ""}
                variant="outlined"
                multiline
                minRows={5}
                maxRows={10}
              />
            </Grid>
          </Grid>
          <Box mt={3} sx={{ flex: 1, flexDirection: "row" }}>
            <Box sx={{ pt: 5 }}>
              {!!imageUrl && (
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img alt="Product" src={imageUrl} width={360} />
                  </Box>
                </CardContent>
              )}
              <CardActions>
                <LoadingButton
                  color="primary"
                  fullWidth
                  variant="text"
                  onClick={() => imageUploadRef.current.click()}
                >
                  앱 이미지 업로드
                </LoadingButton>
              </CardActions>
              <input
                hidden
                type="file"
                accept="image/jpg, image/png, image/jpeg"
                onChange={(e) => {
                  handleChangeImage(e.target.files[0], Platform.APP);
                  imageUploadRef.current.value = null;
                }}
                ref={imageUploadRef}
              />
            </Box>
            <Box sx={{ pt: 5 }}>
              {!!webImageUrl && (
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img alt="Product" src={webImageUrl} width={720} />
                  </Box>
                </CardContent>
              )}
              <CardActions>
                <LoadingButton
                  color="primary"
                  fullWidth
                  variant="text"
                  onClick={() => webImageUploadRef.current.click()}
                >
                  웹 이미지 업로드
                </LoadingButton>
              </CardActions>
              <input
                hidden
                type="file"
                accept="image/jpg, image/png, image/jpeg"
                onChange={(e) => {
                  handleChangeImage(e.target.files[0], Platform.WEB);
                  webImageUploadRef.current.value = null;
                }}
                ref={webImageUploadRef}
              />
            </Box>
          </Box>
          <Box mt={3}>
            <Card>
              <DataGrid
                fixedHeader
                progressPending={isFetching}
                columns={columns}
                data={data?.tagMappings || []}
                title={
                  data ? data?.name + " - " + data?.tagMappings?.length : ""
                }
                paginationTotalRows={data?.tagMappings?.length}
              />
            </Card>
          </Box>
        </Container>
      </Box>
      <input
        hidden
        type="file"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={(e) => {
          handleUpdateTagMappings(e.target.files[0]);
          imageInputRef.current.value = null;
        }}
        ref={imageInputRef}
      />
    </>
  );
};

export default TagCustomEdit;
