import fetchn from "utils/Fetchn";
import { RemoteSubjectCategory, RemoteClassCategory } from "types/Content";
const CategoryApi = (signal?) => {
  return {
    getClassCategories: async () => {
      // GET /manager/v0/categories/classes
      const { data } = await fetchn.request<RemoteClassCategory[]>({
        url:
          process.env.REACT_APP_SERVER_URI + `/manager/v0/categories/classes`,
        signal,
      });
      return data;
    },
    getSubjectCategories: async () => {
      // GET /manager/v0/categories/subjects
      const { data } = await fetchn.request<RemoteSubjectCategory[]>({
        url:
          process.env.REACT_APP_SERVER_URI + `/manager/v0/categories/subjects`,
        signal,
      });
      return data;
    },
    getSubjectCategoriesByClassCategoryId: async (classCategoryId: string) => {
      // GET /manager/v0/categories/mappings/{classCategoryId}
      const { data } = await fetchn.request<RemoteSubjectCategory[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/categories/mappings/${classCategoryId}`,
        signal,
      });

      return data;
    },
  };
};

export default CategoryApi;
