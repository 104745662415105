import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useMutation, useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";

import QueryKey from "constants/QueryKey";
import LibraryApi from "apis/Library";
import dayjs from "dayjs";

const PointEditModal = ({
  libraryId,
  open,
  onClose,
}: {
  libraryId: number;
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const [amount, setAmount] = useState<string>();
  const [validTo, setValidTo] = useState<Date>(null);
  const [adminComment, setAdminComment] = useState<string>();
  const [mode, setMode] = useState("plus");

  const { mutate: addPoint, isLoading: isAdding } = useMutation(
    LibraryApi().addPoint,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.pointTotal, libraryId]);
        queryClient.invalidateQueries(QueryKey.pointsPaging, {
          predicate: (query) => {
            const queryKey = query.queryKey[1];
            return queryKey?.["libraryId"] === libraryId;
          },
        });
        onClose();
      },
    }
  );

  const { mutate: deductPoint, isLoading: isDeducting } = useMutation(
    LibraryApi().deductPoint,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.pointTotal, libraryId]);
        queryClient.invalidateQueries(QueryKey.pointsPaging, {
          predicate: (query) => {
            const queryKey = query.queryKey[1];
            return queryKey?.["libraryId"] === libraryId;
          },
        });
        onClose();
      },
    }
  );

  const handleSubmit = () => {
    if (mode === "minus") {
      deductPoint({
        libraryId,
        amount: parseInt(amount),
        adminComment,
      });
      return;
    }
    addPoint({
      libraryId,
      amount: parseInt(amount),
      validTo: validTo
        ? dayjs(validTo).startOf("d").add(1, "d").toISOString()
        : undefined,
      adminComment,
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">포인트 수정</DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup
              row
              onChange={(e) => setMode(e.target.value)}
              value={mode}
            >
              <FormControlLabel value="plus" control={<Radio />} label="추가" />
              <FormControlLabel
                value="minus"
                control={<Radio />}
                label="차감"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            label="금액"
            margin="dense"
            name="amount"
            type="number"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            variant="outlined"
            fullWidth
          />
          <DatePicker
            label="유효기간"
            value={validTo}
            onChange={setValidTo}
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="dense" />
            )}
            disabled={mode === "minus"}
          />
          <TextField
            label="메모"
            margin="dense"
            name="adminComment"
            onChange={(e) => setAdminComment(e.target.value)}
            value={adminComment}
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            loading={isAdding || isDeducting}
            disabled={!parseInt(amount)}
          >
            수정
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PointEditModal;
