import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Link,
  Switch,
} from "@mui/material";
import { TableColumn } from "react-data-table-component";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";

import BookApi from "apis/Book";
import { DataGrid } from "components";
import { TextPublishType } from "constants/Local";
import QueryKey from "constants/QueryKey";
import { RemoteBookCategoryChangeRequest } from "types/Content";
import useSearchParamsState from "hooks/useSearchParams";
import useCategory from "hooks/useCategory";
import dayjs from "dayjs";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

const getProcessedText = (processed: boolean) => {
  if (processed) return "승인";
  if (processed) return "거부";
  return null;
};

const CategoryChangeRequestList: React.FC = () => {
  const {
    page,
    size,
    processed,
    totalCount,
    isFetching,
    categoryChangeRequests,
    isEditing,
    clearSelectedRowsFlag,
    handleChangeSelectedRows,
    handleChangePage,
    handleChangeSize,
    handleChangeProcessed,
    handlePressApprove,
    handlePressReject,
  } = useBookList();

  const actions = [
    <LoadingButton
      key="approve"
      color="primary"
      sx={{ m: 1 }}
      variant="contained"
      onClick={handlePressApprove}
      loading={isEditing}
    >
      승인
    </LoadingButton>,
    <LoadingButton
      key="reject"
      color="error"
      sx={{ m: 1 }}
      variant="contained"
      onClick={handlePressReject}
      loading={isEditing}
    >
      거부
    </LoadingButton>,
  ];

  const { getClassCategory, getSubjectCategoriesWithParents } = useCategory();

  const columns: TableColumn<RemoteBookCategoryChangeRequest>[] = [
    {
      name: "도서명",
      selector: (row) => row.title,
      cell: (row) => (
        <Link
          color="inherit"
          component={RouterLink}
          to={`/books/${row.bookId}/edit`}
          variant="subtitle2"
        >
          {row.title}
        </Link>
      ),
      wrap: true,
      width: "200px",
    },
    {
      name: "타입",
      selector: (row) => row.publishType,
      format: (row) => TextPublishType[row.publishType],
      grow: 0,
    },
    {
      name: "언어",
      selector: (row) => row.language,
      grow: 0,
    },
    {
      name: "현재 카테고리 (연령)",
      selector: (row) => getClassCategory(row.beforeClassCategoryId)?.shortName,
      width: "140px",
    },
    {
      name: "현재 카테고리 (주제)",
      selector: (row) =>
        getSubjectCategoriesWithParents(row.beforeSubjectCategoryId)
          ?.map((category) => category.name)
          .join(" > "),
    },
    {
      name: "변경 카테고리 (연령)",
      selector: (row) => getClassCategory(row.classCategoryId)?.shortName,
      width: "140px",
    },
    {
      name: "변경 카테고리 (주제)",
      selector: (row) =>
        getSubjectCategoriesWithParents(row.subjectCategoryId)
          ?.map((category) => category.name)
          .join(" > "),
    },
    {
      name: "변경 시리즈명",
      selector: (row) => row.seriesName,
      cell: (row) =>
        row.seriesName &&
        (row.seriesTagId ? (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/tag/tagevents/${row.seriesTagId}`}
            variant="subtitle2"
            target="_blank"
          >
            {row.seriesName}
          </Link>
        ) : (
          <>{`${row.seriesName}(미등록)`}</>
        )),
      wrap: true,
      width: "200px",
    },
    {
      name: "도서관명",
      selector: (row) => row.libraryName,
    },
    {
      name: "처리여부",
      selector: (row) => getProcessedText(row.processed),
    },
    {
      name: "요청일자",
      selector: (row) => dayjs(row.createdAt).format("YYYY-MM-DD"),
    },
  ];

  return (
    <>
      <Helmet>
        <title>카테고리 변경 요청</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Card>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                m: -1,
                p: 2,
              }}
            >
              <Box sx={{ m: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={processed}
                      color="primary"
                      name="processed"
                      onChange={(e) => handleChangeProcessed(e.target.checked)}
                    />
                  }
                  label="처리된 요청 보기"
                />
              </Box>
            </Box>
            <DataGrid
              fixedHeader
              progressPending={isFetching}
              columns={columns}
              data={categoryChangeRequests}
              title="카테고리 변경 요청"
              contextActions={actions}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeSize}
              paginationDefaultPage={page}
              paginationPerPage={size}
              pagination
              paginationTotalRows={totalCount}
              selectableRows
              onSelectedRowsChange={(e) =>
                handleChangeSelectedRows(e.selectedRows)
              }
              clearSelectedRows={clearSelectedRowsFlag}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

const useBookList = () => {
  const { searchParams, setSearchParams } = useSearchParamsState<{
    page: number;
    size: number;
    processed: boolean;
  }>({
    defaultValues: {
      page: 1,
      size: 100,
      processed: null,
    },
    parse: {
      page: (value) => parseInt(value),
      size: (value) => parseInt(value),
      processed: (value) => JSON.parse(value),
    },
  });

  const [selectedRows, setSelectedRows] = useState<
    RemoteBookCategoryChangeRequest[]
  >([]);
  const [clearSelectedRowsFlag, setClearSelectedRowsFlag] =
    useState<boolean>(false);

  const { data, isFetching, refetch } = useQuery(
    [
      QueryKey.categoryChangeRequests,
      {
        page: searchParams.page,
        size: searchParams.size,
        processed: searchParams.processed,
      },
    ],
    () =>
      BookApi().getCategoryChangeRequests({
        page: searchParams.page,
        size: searchParams.size,
        processed: searchParams.processed,
      })
  );

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setClearSelectedRowsFlag(!clearSelectedRowsFlag);
  };

  const { mutate: editCategoryChangeRequests, isLoading: isEditing } =
    useMutation(BookApi().editCategoryChangeRequests, {
      onSuccess: (_, data) => {
        refetch();
        clearSelectedRows();
        if (data) {
          toast.success("승인 처리되었습니다");
        } else {
          toast.success("거부 처리되었습니다");
        }
      },
    });

  return {
    categoryChangeRequests: data?.content,
    totalCount: data?.totalCount,
    isFetching,
    page: searchParams.page,
    size: searchParams.size,
    processed: searchParams.processed,
    isEditing,
    clearSelectedRowsFlag,
    handleChangePage: (page: number) =>
      setSearchParams({ ...searchParams, page }),
    handleChangeSize: (size: number) =>
      setSearchParams({ ...searchParams, size }),
    handleChangeProcessed: (processed: boolean) => {
      setSearchParams({
        ...searchParams,
        processed: processed ? true : null,
        page: 1,
      });
    },
    handleChangeSelectedRows: setSelectedRows,
    handlePressApprove: () => {
      if (window.confirm("승인 하시겠습니까?")) {
        editCategoryChangeRequests(
          selectedRows.map((row) => ({ id: row.id, processed: true }))
        );
      }
    },
    handlePressReject: () => {
      if (window.confirm("거부 하시겠습니까?")) {
        editCategoryChangeRequests(
          selectedRows.map((row) => ({ id: row.id, processed: false }))
        );
      }
    },
  };
};

export default CategoryChangeRequestList;
