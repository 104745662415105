import TagApi from "apis/Tag";
import QueryKey from "constants/QueryKey";
import { useQuery } from "react-query";

const useCustomTag = () => {
  const { data: tags = [], isFetching } = useQuery(
    QueryKey.customtags,
    TagApi().getCustomTags,
    {
      cacheTime: Infinity,
      staleTime: 1000 * 60 * 10,
    }
  );

  return {
    tags,
    isFetching,
  };
};

export default useCustomTag;
