import React from "react";
import OrderChart from "./OrderChart";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardHeader, Divider } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import UserChart from "./UserChart";
import { floor, random } from "lodash";

const useStyles = makeStyles({
  root: {},
  chart: {
    height: 200,
  },
});

const User = ({ dashboardinfo }: { dashboardinfo: any }) => {
  let joincounts = [];
  let joindates = [];

  let joincount_all = [];
  let joincount_kakao = [];
  let joincount_google = [];
  let joincount_apple = [];
  dashboardinfo.joinBySnsList.map((info) => {
    joincount_all = [
      ...joincount_all,
      Number(info.kakaoJoinCount) +
        Number(info.googleJoinCount) +
        Number(info.appleJoinCount),
    ];
    joincount_kakao = [...joincount_kakao, Number(info.kakaoJoinCount)];
    joincount_google = [...joincount_google, Number(info.googleJoinCount)];
    joincount_apple = [...joincount_apple, Number(info.appleJoinCount)];

    joindates = [...joindates, info.date];
  });

  const classes = useStyles();
  const stats = {
    all: joincount_all,
    kakao: joincount_kakao,
    google: joincount_google,
    apple: joincount_apple,
  };
  const labels = joindates;

  return (
    <Card>
      <CardHeader title="일별 가입자" />
      <Divider />
      <PerfectScrollbar>
        <Box pt={4} pr={2} pl={2}>
          <UserChart className={classes.chart} data={stats} labels={labels} />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default User;
