import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "react-query";
import { DeliveryMethod, ImageType, SortContent } from "constants/Server";
import CommonApi from "apis/Common";
import useCategory from "../hooks/useCategory";
import QueryKey from "../constants/QueryKey";
import TagApi from "../apis/Tag";

const getQueryParams = (link: string) => {
  const regex = /(\w+)\=(\w+)/g;
  const params = {};
  let matches;
  while ((matches = regex.exec(link))) {
    params[matches[1]] = matches[2];
  }
  return params;
};

const tagDatas = [
  { title: "웹뷰", value: "webview" },
  { title: "이벤트도서목록", value: "booksevent" },
  { title: "카테고리목록", value: "categorylist" },
  { title: "시리즈 카테고리목록", value: "seriescategorylist" },
  { title: "내 도서관", value: "mylibrary" },
  { title: "내 북마크로 이동", value: "bookmarks" },
  { title: "소장도서목록", value: "mybookitems" },
  { title: "위탁도서목록", value: "mybookitemsconsigned" },
  { title: "장바구니", value: "cart" },
  { title: "book 상세페이지", value: "bookitem" },
  { title: "태마북 태그", value: "themebooktag" },
  { title: "이벤트 태그", value: "tagevents" },
  { title: "도서관 픽목록", value: "libraryPick" },
  { title: "픽 상세", value: "pickDetail" },
];

const sortDatas = [
  { title: "베스트셀러", value: SortContent.LAST_MONTH_RENTAL_COUNT },
  { title: "스테디셀러", value: SortContent.RENTAL_COUNT },
  { title: "출간일순", value: SortContent.PUBLICATION },
  { title: "북마크순", value: SortContent.BOOKMARK_COUNT },
  { title: "리뷰순", value: SortContent.REVIEW_COUNT },
];

const themetypes = [
  { id: 0, title: "할로윈", value: "halloween" },
  { id: 1, title: "기본형", value: "default" },
  { id: 2, title: "크리스마스", value: "xm" },
  { id: 3, title: "새해", value: "ny" },
];

const eventtagtypes = [{ id: 0, title: "리딩게이트", value: "readinggate" }];

export enum ClassCategoryGroup {
  AGE = "AGE",
  LEVEL = "LEVEL",
  PARENT = "PARENT",
}

export enum PublishType {
  SINGLE = "SINGLE",
  SET = "SET",
  PERIODICAL = "PERIODICAL",
  BOARD_GAME = "BOARD_GAME",
}

export enum ROOT_CLASSCATEGORY_ID {
  CHILD = "10",
  ELEMENTARY = "11",
  SECONDARY = "12",
  HIGH = "13",
  ENGLISH_BEGINNER = "20",
  ENGLISH_INTERMEDIATE = "21",
  ENGLISH_ADVANCED = "22",
  ENGLISH_MASTER = "23",
  ADULT = "30",
}

export const ClassCategoryTopIds: {
  readonly [key in ClassCategoryGroup]: string[];
} = {
  [ClassCategoryGroup.AGE]: [
    ROOT_CLASSCATEGORY_ID.CHILD,
    ROOT_CLASSCATEGORY_ID.ELEMENTARY,
    ROOT_CLASSCATEGORY_ID.SECONDARY,
    ROOT_CLASSCATEGORY_ID.HIGH,
  ],
  [ClassCategoryGroup.LEVEL]: [
    ROOT_CLASSCATEGORY_ID.ENGLISH_BEGINNER,
    ROOT_CLASSCATEGORY_ID.ENGLISH_INTERMEDIATE,
    ROOT_CLASSCATEGORY_ID.ENGLISH_ADVANCED,
    ROOT_CLASSCATEGORY_ID.ENGLISH_MASTER,
  ],
  [ClassCategoryGroup.PARENT]: [ROOT_CLASSCATEGORY_ID.ADULT],
};

const useAllTag = () => {
  const { data: tags = [], isFetching } = useQuery(
    QueryKey.alltags,
    TagApi().getAllTags,
    {
      cacheTime: Infinity,
      staleTime: 1000 * 60 * 10,
    }
  );

  return {
    tags,
    isFetching,
  };
};

const DeepLinkGenerationModal = ({
  link,
  open,
  onSubmit,
  onClose,
}: {
  link: string;
  open: boolean;
  onSubmit: (link: string) => void;
  onClose: () => void;
}) => {
  const imageInputRef = useRef<HTMLInputElement | null>();
  const { tags } = useAllTag();
  const [type, setType] = useState<string>("webview");
  const [sort, setSort] = useState<SortContent>();
  const [webviewUrl, setWebViewUrl] = useState<string>("");
  const {
    getClassCategory,
    getTopClassCategory,
    getSubjectCategory,
    getClassCategoriesWithParents,
    getLeafClassCategories,
    getChildClassCategories,
  } = useCategory();

  const [headerTitle, setHeaderTitle] = useState<string>("");
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [hasFilter, setHasFilter] = useState<boolean>();
  const [hasSort, setHasSort] = useState<boolean>();
  const [hasWoodoShop, setHasWoodoShop] = useState<boolean>();
  const [sellable, setSellable] = useState<boolean>();
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>();
  const { classCategories } = useCategory();

  const [filter, setFilter] = useState("ALL");
  const [agecategory, setAgecategory] = useState("");
  const [classcategory, setClasscategory] = useState("");
  const [classcategorytitle, setClasscategorytitle] = useState("");
  const [publishType, setPublishType] = useState(PublishType.SINGLE);

  const [multipleClasscategory, setMultipleClasscategory] = useState<string[]>(
    []
  );

  const [bookitemid, setBookitemId] = useState<string>("");
  const [themetype, setThemeType] = useState<string>("");
  const [eventtagtype, setEventtagtype] = useState<string>("");
  const [libraryId, setLibraryId] = useState<number>();
  const [pickId, setPickId] = useState<number>();

  // console.log ( getTopClassCategory(ClassCategoryTopIds[ClassCategoryGroup.AGE]))

  useEffect(() => {
    if (sort === SortContent.CUSTOM_RANK) {
      setHasSort(false);
    }
  }, [sort]);

  useEffect(() => {
    if (link?.includes("woodo://")) {
      // const regex = /(?<=\/)([^\/?]+)(?=[\/\?])/g
      const regex = /(?:\/)([^\/?]+)(?=[\/\?])/g;
      const pathnames = link.match(regex);
      const queryParams = getQueryParams(link);

      if (pathnames != null) {
        if (pathnames[0] === "webview") {
          setType("webview");
          const [, webviewUrl] = pathnames;
          setWebViewUrl(webviewUrl ? decodeURIComponent(webviewUrl) : null);
        } else if (pathnames[0] === "categorylist") {
          setType("categorylist");
          const [, category] = pathnames;
          //setWebViewUrl(webviewUrl ? decodeURIComponent(webviewUrl) : null);
        } else if (pathnames[0] === "seriescategorylist") {
          setType("seriescategorylist");
          const [, category] = pathnames;
          //setWebViewUrl(webviewUrl ? decodeURIComponent(webviewUrl) : null);
        } else if (pathnames[0] === "booksevent") {
          setType("booksevent");
          const [
            ,
            tagIds,
            headerTitle,
            classcategoryTopId,
            classCategoryIds,
            imageUrl,
          ] = pathnames;
          setTagIds(tagIds?.split(",")?.map((tagId) => +tagId) || []);
          setHeaderTitle(headerTitle ? decodeURIComponent(headerTitle) : null);
          setImageUrl(imageUrl ? decodeURIComponent(imageUrl) : null);
          setMultipleClasscategory(classCategoryIds.split(","));
          if (queryParams["hasFilter"]) {
            setHasFilter(queryParams["hasFilter"]);
          }
          if (queryParams["hasSort"]) {
            setHasSort(queryParams["hasSort"]);
          }
          if (queryParams["sort"]) {
            setSort(queryParams["sort"]);
          }
          if (queryParams["deliveryMethod"]) {
            setDeliveryMethod(queryParams["deliveryMethod"]);
          }
          if (queryParams["sellableInShop"]) {
            setHasWoodoShop(queryParams["sellableInShop"]);
          }
          if (queryParams["sellable"]) {
            setSellable(queryParams["sellable"]);
          }
        } else if (pathnames[0] === "mylibrary") {
          setType("mylibrary");
          const [, category] = pathnames;
          //setWebViewUrl(webviewUrl ? decodeURIComponent(webviewUrl) : null);
        } else {
        }
      }
    }
  }, [link]);

  const { mutate: uploadImage, isLoading: isUploading } = useMutation(
    (image: File) =>
      CommonApi().uploadImage({ image, imageType: ImageType.BANNER }),
    {
      onSuccess: (data) => {
        setImageUrl(data.url);
      },
    }
  );

  const handleSubmit = () => {
    if (type === "webview") {
      onSubmit(`woodo://webview/${encodeURIComponent(webviewUrl)}`);
    } else if (type === "categorylist") {
      const info =
        classcategory != "" ? classcategory.split(",") : agecategory.split(",");
      const defaultfilter = "ALL";
      let categoryurl = "";
      if (info[0] == "boardgames") categoryurl = `woodo://boardgames`;
      else
        categoryurl = `woodo://bookscategories/${info[0]}/${
          info[1]
        }/${defaultfilter}/${encodeURIComponent(
          classcategorytitle
        )}/${encodeURIComponent(publishType)}`;
      onSubmit(categoryurl);
    } else if (type === "seriescategorylist") {
      const info =
        classcategory != "" ? classcategory.split(",") : agecategory.split(",");
      const defaultfilter = "ALL";
      let categoryurl = "";
      if (info[0] == "boardgames") categoryurl = `woodo://boardgames`;
      else
        categoryurl = `woodo://booksseriescategories/${info[0]}/${
          info[1]
        }/${defaultfilter}/${encodeURIComponent(classcategorytitle)}`;
      onSubmit(categoryurl);
    } else if (type == "mylibrary") {
      const link = "woodo://mylibrary/";
      onSubmit(link);
    } else if (type == "mybookitems") {
      const link = "woodo://mybookitems/";
      onSubmit(link);
    } else if (type == "mybookitemsconsigned") {
      const link = "woodo://mybookitemsconsigned/";
      onSubmit(link);
    } else if (type == "cart") {
      const link = "woodo://cart/";
      onSubmit(link);
    } else if (type == "bookmarks") {
      const link = "woodo://bookmarks/";
      onSubmit(link);
    } else if (type == "bookitem") {
      const link = `woodo://bookitem/${bookitemid}`;
      onSubmit(link);
    } else if (type == "themebooktag") {
      let link = `woodo://themebooktag/${themetype}/${tagIds.join(
        ","
      )}/${encodeURIComponent(headerTitle)}`;
      if (!!imageUrl) link = link + "/" + encodeURIComponent(imageUrl);
      else link = link + "/";

      link = link + "/true/true";
      link = link + "/" + filter;

      onSubmit(link);
    } else if (type == "tagevents") {
      let link = `woodo://tagevents/${eventtagtype}/${tagIds.join(
        ","
      )}/${encodeURIComponent(headerTitle)}`;
      if (!!imageUrl) link = link + "/" + encodeURIComponent(imageUrl);
      else link = link + "/";

      onSubmit(link);
    } else if (type === "booksevent") {
      let link = `woodo://booksevent/${tagIds.join(",")}/${encodeURIComponent(
        headerTitle
      )}`;
      if (agecategory !== "") {
        link = link + "/" + agecategory.split(",")[0];
      }
      if (multipleClasscategory.length !== 0) {
        link = link + "/" + multipleClasscategory.join(",");
      }
      if (!!imageUrl) {
        link = link + "/" + encodeURIComponent(imageUrl);
      }

      const params = [];
      if (hasFilter) {
        params.push(`hasFilter=${hasFilter}`);
      }
      if (hasSort) {
        params.push(`hasSort=${hasSort}`);
      }
      if (sort) {
        params.push(`sort=${sort}`);
      }
      if (deliveryMethod) {
        params.push(`deliveryMethod=${deliveryMethod}`);
      }
      if (hasWoodoShop) {
        params.push(`sellableInShop=${hasWoodoShop}`);
      }
      if (sellable) {
        params.push(`sellable=${sellable}`);
      }

      if (params.length > 0) {
        link = link + "?" + params.join("&");
      }
      onSubmit(link);
    } else if (type === "libraryPick") {
      let link = `woodo://userlibrarypick/${libraryId}`;

      onSubmit(link);
    } else if (type === "pickDetail") {
      let link = `woodo://userlibrarypickview/${pickId}`;

      onSubmit(link);
    }

    onClose();
  };

  const handleChangeImage = (image) => {
    if (image) {
      uploadImage(image);
    }
  };

  const Categories = [
    {
      title: "한글",
      classCategoryGroup: ClassCategoryGroup.AGE,
      publishType: PublishType.SINGLE,
    },

    {
      title: "영어",
      classCategoryGroup: ClassCategoryGroup.LEVEL,
      publishType: PublishType.SINGLE,
    },
  ];

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">딥링크 생성</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="타입"
              name="bannerType"
              onChange={(e) => setType(e.target.value)}
              select
              SelectProps={{ native: true }}
              value={type}
              variant="outlined"
              required
            >
              {tagDatas.map((tag, idx) => (
                <option key={idx} value={tag.value}>
                  {tag.title}
                </option>
              ))}
            </TextField>
            {type === "categorylist" && (
              <>
                <TextField
                  sx={{ mt: 3 }}
                  InputLabelProps={{ shrink: true }}
                  label="타이틀"
                  margin="dense"
                  name="classcategorytitle"
                  onChange={(e) => setClasscategorytitle(e.target.value)}
                  value={classcategorytitle}
                  variant="outlined"
                  fullWidth
                />

                <Box>
                  <InputLabel id="tagIds-label" sx={{ mt: 3 }}>
                    TopClasses (유아,초등,중등,고등, 영어, 부모의책)
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    defaultValue={""}
                    value={agecategory || ""}
                    onChange={(e) => setAgecategory(e.target.value)}
                    fullWidth
                  >
                    {Categories.map((info) =>
                      ClassCategoryTopIds[info.classCategoryGroup].map(
                        (categoryId) => (
                          <MenuItem
                            key={categoryId + "," + "0"}
                            value={categoryId + "," + "0"}
                          >
                            {info.title} / {getClassCategory(categoryId)?.name}
                          </MenuItem>
                        )
                      )
                    )}

                    <MenuItem
                      key={
                        ClassCategoryTopIds[ClassCategoryGroup.PARENT][0] +
                        "," +
                        "0"
                      }
                      value={
                        ClassCategoryTopIds[ClassCategoryGroup.PARENT][0] +
                        "," +
                        "0"
                      }
                    >
                      {"부모의책"}
                    </MenuItem>
                  </Select>
                </Box>

                <Box>
                  <InputLabel id="tagIds-label" sx={{ mt: 3 }}>
                    클래스
                  </InputLabel>

                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    defaultValue={""}
                    value={classcategory || ""}
                    onChange={(e) => setClasscategory(e.target.value)}
                    fullWidth
                  >
                    {getChildClassCategories([agecategory.split(",")[0]]).map(
                      (info) => (
                        <MenuItem
                          key={info.parentId + "," + info.id}
                          value={info.parentId + "," + info.id}
                        >
                          {info.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Box>

                <Box>
                  <InputLabel id="tagIds-label" sx={{ mt: 3 }}>
                    단행본 / 세트
                  </InputLabel>

                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    defaultValue={PublishType.SINGLE}
                    value={publishType || ""}
                    onChange={(e) =>
                      setPublishType(e.target.value as PublishType)
                    }
                    fullWidth
                  >
                    <MenuItem value={PublishType.SINGLE}>단행본</MenuItem>
                    <MenuItem value={PublishType.SET}>세트</MenuItem>
                  </Select>
                </Box>
              </>
            )}

            {type === "seriescategorylist" && (
              <>
                <TextField
                  sx={{ mt: 3 }}
                  InputLabelProps={{ shrink: true }}
                  label="타이틀"
                  margin="dense"
                  name="classcategorytitle"
                  onChange={(e) => setClasscategorytitle(e.target.value)}
                  value={classcategorytitle}
                  variant="outlined"
                  fullWidth
                />

                <Box>
                  <InputLabel id="tagIds-label" sx={{ mt: 3 }}>
                    TopClasses (유아,초등,중등,고등, 영어)
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    defaultValue={""}
                    value={agecategory || ""}
                    onChange={(e) => setAgecategory(e.target.value)}
                    fullWidth
                  >
                    {Categories.map((info) =>
                      ClassCategoryTopIds[info.classCategoryGroup].map(
                        (categoryId) => (
                          <MenuItem
                            key={categoryId + "," + "0"}
                            value={categoryId + "," + "0"}
                          >
                            {info.title} / {getClassCategory(categoryId)?.name}
                          </MenuItem>
                        )
                      )
                    )}
                  </Select>
                </Box>

                <Box>
                  <InputLabel id="tagIds-label" sx={{ mt: 3 }}>
                    클래스
                  </InputLabel>

                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    defaultValue={""}
                    value={classcategory || ""}
                    onChange={(e) => setClasscategory(e.target.value)}
                    fullWidth
                  >
                    {getChildClassCategories([agecategory.split(",")[0]]).map(
                      (info) => (
                        <MenuItem
                          key={info.parentId + "," + info.id}
                          value={info.parentId + "," + info.id}
                        >
                          {info.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Box>
              </>
            )}

            {type === "themebooktag" && (
              <>
                <TextField
                  sx={{ mt: 4 }}
                  InputLabelProps={{ shrink: true }}
                  label="제목"
                  margin="dense"
                  name="headerTitle"
                  onChange={(e) => setHeaderTitle(e.target.value)}
                  value={headerTitle}
                  variant="outlined"
                  fullWidth
                />
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="tagIds-label">태그</InputLabel>
                  <Select
                    labelId="tagIds-label"
                    id="tagIds"
                    multiple
                    value={tagIds}
                    fullWidth
                    margin="dense"
                    onChange={(e) => setTagIds(e.target.value as number[])}
                    input={<OutlinedInput label="태그" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={tags.find((tag) => tag.id === value)?.name}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {tags.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        <Checkbox checked={tagIds.indexOf(id) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="tagIds-label">테마타입</InputLabel>
                  <Select
                    labelId="tagIds-label"
                    id="tagIds"
                    value={themetype}
                    fullWidth
                    margin="dense"
                    onChange={(e) => setThemeType(e.target.value)}
                    input={<OutlinedInput label="테마타입" />}
                  >
                    {themetypes.map(({ id, title, value }) => (
                      <MenuItem key={id} value={value}>
                        <ListItemText primary={title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box sx={{ mt: 0 }}>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="tagIds-label">필터종류</InputLabel>
                    <Select
                      labelId="tagIds-label"
                      id="tagIds"
                      value={filter}
                      fullWidth
                      margin="dense"
                      onChange={(e) => setFilter(e.target.value)}
                      input={<OutlinedInput label="필터종류" />}
                    >
                      <MenuItem value={"ALL"}>한글/영어 모두보임</MenuItem>
                      <MenuItem value={"KOREAN"}>한글</MenuItem>
                      <MenuItem value={"ENGLISH"}>영어</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <>
                  <LoadingButton
                    color="primary"
                    fullWidth
                    variant="text"
                    sx={{ mt: 2 }}
                    loading={isUploading}
                    onClick={() => imageInputRef.current.click()}
                  >
                    헤더 이미지 수정
                  </LoadingButton>
                  {!!imageUrl && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img alt="Product" src={imageUrl} height={140} />
                    </Box>
                  )}
                  <input
                    hidden
                    type="file"
                    onChange={(e) => {
                      handleChangeImage(e.target.files[0]);
                      imageInputRef.current.value = null;
                    }}
                    ref={imageInputRef}
                  />
                </>
              </>
            )}
            {type === "webview" && (
              <TextField
                sx={{ mt: 3 }}
                InputLabelProps={{ shrink: true }}
                label="상세페이지 URL"
                margin="dense"
                name="webviewUrl"
                onChange={(e) => setWebViewUrl(e.target.value)}
                value={webviewUrl}
                variant="outlined"
                fullWidth
              />
            )}
            {type === "bookitem" && (
              <TextField
                sx={{ mt: 3 }}
                InputLabelProps={{ shrink: true }}
                label="book id"
                margin="dense"
                name="bookitem"
                onChange={(e) => setBookitemId(e.target.value)}
                value={bookitemid}
                variant="outlined"
                fullWidth
              />
            )}
            {type === "booksevent" && (
              <>
                <TextField
                  sx={{ mt: 4 }}
                  InputLabelProps={{ shrink: true }}
                  label="제목"
                  margin="dense"
                  name="headerTitle"
                  onChange={(e) => setHeaderTitle(e.target.value)}
                  value={headerTitle}
                  variant="outlined"
                  fullWidth
                />
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="tagIds-label">태그</InputLabel>
                  <Select
                    labelId="tagIds-label"
                    id="tagIds"
                    multiple
                    value={tagIds}
                    fullWidth
                    margin="dense"
                    onChange={(e) => setTagIds(e.target.value as number[])}
                    input={<OutlinedInput label="태그" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={tags.find((tag) => tag.id === value)?.name}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {tags.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        <Checkbox checked={tagIds.indexOf(id) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box>
                  <InputLabel id="tagIds-label" sx={{ mt: 3 }}>
                    TopClasses (유아,초등,중등,고등, 영어)
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    defaultValue={""}
                    value={agecategory || ""}
                    onChange={(e) => {
                      setAgecategory(e.target.value);
                      setMultipleClasscategory([]);
                    }}
                    fullWidth
                  >
                    {Categories.map((info) =>
                      ClassCategoryTopIds[info.classCategoryGroup].map(
                        (categoryId) => (
                          <MenuItem
                            key={categoryId + "," + "0"}
                            value={categoryId + "," + "0"}
                          >
                            {info.title} / {getClassCategory(categoryId)?.name}
                          </MenuItem>
                        )
                      )
                    )}
                  </Select>
                </Box>

                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel id="tagIds-label">클래스</InputLabel>
                  <Select
                    labelId="tagIds-label"
                    id="tagIds"
                    multiple
                    defaultValue={[]}
                    value={multipleClasscategory || []}
                    fullWidth
                    margin="dense"
                    onChange={(e) =>
                      setMultipleClasscategory(e.target.value as string[])
                    }
                    label="클래스"
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected?.map((value) => {
                          return (
                            <Chip
                              key={value}
                              label={
                                getChildClassCategories([
                                  agecategory.split(",")[0],
                                ]).find((tag) => tag.id === value)?.name
                              }
                            />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {getChildClassCategories([agecategory.split(",")[0]]).map(
                      (info) => {
                        return (
                          <MenuItem
                            key={info.parentId + "," + info.id}
                            value={info.id}
                          >
                            <Checkbox
                              checked={
                                multipleClasscategory.indexOf(info.id) > -1
                              }
                            />
                            <ListItemText primary={info.name} />
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ mt: 4 }}
                  fullWidth
                  label="기본정렬"
                  name="sort"
                  onChange={(e) =>
                    setSort(
                      e.target.value === ""
                        ? null
                        : (e.target.value as SortContent)
                    )
                  }
                  select
                  SelectProps={{ native: true }}
                  value={sort}
                  variant="outlined"
                  required
                >
                  <>
                    <option value={""}>기본정렬</option>
                    {sortDatas.map((sortData) => (
                      <option value={sortData.value} key={sortData.value}>
                        {sortData.title}
                      </option>
                    ))}
                  </>
                </TextField>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ mt: 4 }}
                  fullWidth
                  label="배송방법"
                  name="deliveryMethod"
                  onChange={(e) =>
                    setDeliveryMethod(
                      e.target.value === ""
                        ? null
                        : (e.target.value as DeliveryMethod)
                    )
                  }
                  select
                  SelectProps={{ native: true }}
                  value={deliveryMethod}
                  variant="outlined"
                  required
                >
                  <>
                    <option value={""}>전체</option>
                    <option value={DeliveryMethod.PARCEL}>문앞배송</option>
                    <option value={DeliveryMethod.DIRECT}>직거래</option>
                  </>
                </TextField>
                <FormControlLabel
                  sx={{ ml: 1, mt: 1 }}
                  control={
                    <Switch
                      checked={hasFilter}
                      color="primary"
                      edge="start"
                      name="hasFilter"
                      onChange={(e) =>
                        setHasFilter(e.target.checked ? true : undefined)
                      }
                    />
                  }
                  label="필터"
                />
                <FormControlLabel
                  sx={{ ml: 1, mt: 1 }}
                  control={
                    <Switch
                      checked={hasSort}
                      color="primary"
                      edge="start"
                      name="hasSort"
                      onChange={(e) =>
                        setHasSort(e.target.checked ? true : undefined)
                      }
                    />
                  }
                  label="정렬"
                />
                <FormControlLabel
                  sx={{ ml: 1, mt: 1 }}
                  control={
                    <Switch
                      checked={hasWoodoShop}
                      color="primary"
                      edge="start"
                      name="hasWoodoShop"
                      onChange={(e) =>
                        setHasWoodoShop(e.target.checked ? true : undefined)
                      }
                    />
                  }
                  label="우도샵전용"
                />
                <FormControlLabel
                  sx={{ ml: 1, mt: 1 }}
                  control={
                    <Switch
                      checked={sellable}
                      color="primary"
                      edge="start"
                      name="sellable"
                      onChange={(e) =>
                        setSellable(e.target.checked ? true : undefined)
                      }
                    />
                  }
                  label="중고 도서 판매"
                />
                <>
                  <LoadingButton
                    color="primary"
                    fullWidth
                    variant="text"
                    sx={{ mt: 2 }}
                    loading={isUploading}
                    onClick={() => imageInputRef.current.click()}
                  >
                    헤더 이미지 수정
                  </LoadingButton>
                  {!!imageUrl && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img alt="Product" src={imageUrl} height={140} />
                    </Box>
                  )}
                  <input
                    hidden
                    type="file"
                    onChange={(e) => {
                      handleChangeImage(e.target.files[0]);
                      imageInputRef.current.value = null;
                    }}
                    ref={imageInputRef}
                  />
                </>
              </>
            )}

            {type === "tagevents" && (
              <>
                <TextField
                  sx={{ mt: 4 }}
                  InputLabelProps={{ shrink: true }}
                  label="제목"
                  margin="dense"
                  name="headerTitle"
                  onChange={(e) => setHeaderTitle(e.target.value)}
                  value={headerTitle}
                  variant="outlined"
                  fullWidth
                />

                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="tagIds-label">태그</InputLabel>
                  <Select
                    labelId="tagIds-label"
                    id="tagIds"
                    multiple
                    value={tagIds}
                    fullWidth
                    margin="dense"
                    onChange={(e) => setTagIds(e.target.value as number[])}
                    input={<OutlinedInput label="태그" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={tags.find((tag) => tag.id === value)?.name}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {tags.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        <Checkbox checked={tagIds.indexOf(id) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="tagIds-label">이벤트 종류</InputLabel>
                  <Select
                    labelId="tagIds-label"
                    id="tagIds"
                    value={eventtagtype}
                    fullWidth
                    margin="dense"
                    onChange={(e) => setEventtagtype(e.target.value)}
                    input={<OutlinedInput label="이벤트 태그 타입" />}
                  >
                    {eventtagtypes.map(({ id, title, value }) => (
                      <MenuItem key={id} value={value}>
                        <ListItemText primary={title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <>
                  <LoadingButton
                    color="primary"
                    fullWidth
                    variant="text"
                    sx={{ mt: 2 }}
                    loading={isUploading}
                    onClick={() => imageInputRef.current.click()}
                  >
                    헤더 이미지 수정
                  </LoadingButton>
                  {!!imageUrl && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img alt="Product" src={imageUrl} height={140} />
                    </Box>
                  )}
                  <input
                    hidden
                    type="file"
                    onChange={(e) => {
                      handleChangeImage(e.target.files[0]);
                      imageInputRef.current.value = null;
                    }}
                    ref={imageInputRef}
                  />
                </>
              </>
            )}
            {type === "libraryPick" && (
              <TextField
                sx={{ mt: 3 }}
                InputLabelProps={{ shrink: true }}
                label="library id"
                margin="dense"
                name="libraryId"
                type="number"
                onChange={(e) => setLibraryId(+e.target.value)}
                value={libraryId}
                variant="outlined"
                fullWidth
              />
            )}
            {type === "pickDetail" && (
              <TextField
                sx={{ mt: 3 }}
                InputLabelProps={{ shrink: true }}
                label="pick id"
                margin="dense"
                name="pickId"
                type="number"
                onChange={(e) => setPickId(+e.target.value)}
                value={pickId}
                variant="outlined"
                fullWidth
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            생성
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeepLinkGenerationModal;
