import React from "react";
import PaymentChart from "./PaymentChart";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardHeader, Divider } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles({
  root: {},
  chart: {
    height: 400,
  },
});

const Payment = ({ dashboardinfo }: { dashboardinfo: any }) => {
  let salesamount = [];
  let salesdate = [];
  dashboardinfo.salesList.map((info) => {
    salesamount = [...salesamount, info.amount];
    salesdate = [...salesdate, info.date];
  });

  const classes = useStyles();
  const performance = {
    thisWeek: {
      data: [],
      labels: [],
    },
    thisMonth: {
      data: [],
      labels: [],
    },
    thisYear: {
      data: salesamount,
      labels: salesdate,
    },
  };

  return (
    <Card>
      <CardHeader title="매출 현황" />
      <Divider />
      <PerfectScrollbar>
        <Box pt={4} pr={2} pl={2}>
          <PaymentChart
            className={classes.chart}
            data={performance.thisYear.data}
            labels={performance.thisYear.labels}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default Payment;
