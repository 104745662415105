import { RemoteOrder } from "types/Order";
import style from "./Labels.module.css";

const Labels = ({ data }: { data: RemoteOrder[] }) => {
  const chunks = splitArray(data, 20);
  return (
    <>
      {chunks.map((chunk) => (
        <div className={style.page}>
          {chunk.map((order) => (
            <div className={style.label}>
              <div className={style.content}>
                <div
                  style={{
                    flex: 1,
                    textAlign: "right",
                    alignSelf: "center",
                    borderWidth: 1,
                  }}
                >
                  <div style={{ fontSize: "4mm" }}>
                    {order.address.sggNm} {order.address.emdNm}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginLeft: "4mm",
                  }}
                >
                  <div style={{ width: "34mm" }}>
                    <div style={{ fontWeight: "bold", fontSize: "4.5mm" }}>
                      {order.buyerLibrary.name}
                    </div>
                    <div style={{ marginTop: "1mm", fontSize: "3.5mm" }}>
                      {order.orderNo}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

const splitArray = <T extends any>(data: T[], count) => {
  let chunks: T[][] = [];
  for (let i = 0; i < data.length; i = i + count) {
    chunks = [...chunks, data.slice(i, i + count)];
  }
  return chunks;
};

export default Labels;
