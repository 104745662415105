import { useRef, useState } from "react";
import type { FC } from "react";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography,
} from "@mui/material";

import useAuth from "hooks/useAuth";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { authData, signOut } = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await signOut();
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{ alignItems: "center", display: "flex" }}
      >
        <Avatar src={null} sx={{ height: 32, width: 32 }} />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { width: 240 } }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {authData?.adminName}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {authData?.email}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            로그아웃
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
