import type { FC } from "react";
import type { Theme } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import type { SxProps } from "@mui/system";
import { Box } from "@mui/material";

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled("svg")``;

const Logo: FC<LogoProps> = (props) => (
  <Box>
    <img alt="logo" src="/static/logo1.svg" height={50} width={150} />
  </Box>
);

export default Logo;
