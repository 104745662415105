import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as XLSX from "xlsx";

import TagApi from "apis/Tag";
import QueryKey from "constants/QueryKey";
import { DataGrid, DeepLinkGenerationModal } from "components";
import { TableColumn } from "react-data-table-component";
import { TagMapping } from "types/Content";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import CommonApi from "../../../apis/Common";
import { ImageType } from "../../../constants/Server";
import { Label } from "@mui/icons-material";
import HomeTagApi from "../../../apis/HomeTag";

const parseParams = (params) => {
  return {
    tagId: parseInt(params.tagId),
  };
};

const HomeTagEdit = () => {
  const imageInputRef = useRef<HTMLInputElement | null>();
  const imageUploadRef = useRef<HTMLInputElement | null>();

  const queryClient = useQueryClient();
  const params = useParams();
  const paramsParsed = useMemo(() => parseParams(params), [params]);
  const navigate = useNavigate();

  const columns: TableColumn<TagMapping>[] = useMemo(
    () => [
      {
        name: "Book ID",
        selector: (row) => row.bookId,
        width: "100px",
      },
      {
        name: "제목",
        selector: (row) => row.title,
        wrap: true,
      },
      {
        name: "ISBN",
        selector: (row) => row.isbn,
        wrap: true,
      },
    ],
    []
  );

  const { data, isFetching } = useQuery(
    [QueryKey.tag, { tagId: paramsParsed.tagId }],
    () => HomeTagApi().getTagWithBooks(paramsParsed.tagId)
  );

  const { mutate: deleteTag, isLoading: isDeleting } = useMutation(
    () => HomeTagApi().deleteTag(paramsParsed.tagId),
    {
      onSuccess: () => {
        toast.success("삭제되었습니다.");
        queryClient.invalidateQueries(QueryKey.hometags);
        navigate(-1);
      },
    }
  );

  const { mutate: updateTagMappings, isLoading: isUpdating } = useMutation(
    (bookIds: number[]) =>
      HomeTagApi().updateTagMappings(paramsParsed.tagId, bookIds),
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        queryClient.invalidateQueries([
          QueryKey.hometag,
          { tagId: paramsParsed.tagId },
        ]);
      },
    }
  );

  const handleUpdateTagMappings = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: "binary", cellDates: true });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const json: any[] = XLSX.utils.sheet_to_json(ws, {
        header: "A",
        blankrows: false,
        raw: true,
        defval: "",
        range: 1,
      });
      updateTagMappings(json.map((data) => data.A));
    };
    reader.readAsBinaryString(file);
  };

  const handleDeleteTag = () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      deleteTag();
    }
  };

  const handlePressExport = () => {
    const ws = XLSX.utils.json_to_sheet(
      data.tagMappings.map((tagMapping) => ({
        "Book ID": tagMapping.bookId,
        도서명: tagMapping.title,
        ISBN: tagMapping.isbn,
        이미지: tagMapping.imageUrl,
      })),
      {
        header: ["Book ID", "도서명", "ISBN", "이미지"],
      }
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(
      wb,
      dayjs().format(`태그도서목록_${data.name}_YYYYMMDDHHmm`) + ".xlsx"
    );
  };

  const { mutate: uploadImage, isLoading: isUploading } = useMutation(
    (image: File) =>
      CommonApi().uploadImage({ image, imageType: ImageType.HOMETAG }),
    {
      onSuccess: (data) => {
        setImageurl(data.url);
      },
    }
  );

  const handleChangeImage = (image) => {
    if (image) {
      uploadImage(image);
    }
  };

  const { mutate: modify } = useMutation(
    ({
      title,
      filter,
      imageurl,
      enabled,
    }: {
      title: string;
      filter: string;
      imageurl: string;
      enabled: boolean;
    }) =>
      HomeTagApi().modifyTag(
        paramsParsed.tagId,
        title,
        filter,
        imageurl,
        enabled
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.hometags);
      },
    }
  );

  const handleModify = () => {
    if (window.confirm("정말 수정하시겠습니까?")) {
      const info = {
        title: tagname,
        filter: filter,
        imageurl: imageurl,
        enabled: enabled,
      };

      // console.log ( info);
      modify(info);
    }
  };

  const [tagname, setTagName] = useState("");
  const [filter, setFilter] = useState("");
  const [imageurl, setImageurl] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deeplinkURL, setDeeplinkURL] = useState("");

  useEffect(() => {
    if (data != null) {
      setTagName(data.name);
      setFilter(data.filter);
      setImageurl(data.imageUrl);
      setEnabled(data.enabled ? true : false);

      console.log(data.filter);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{"홈 태그 상세"}</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {"홈 태그 상세"}
              </Typography>
            </Grid>

            <Grid item>
              <Typography color="textPrimary">{"활성화 여부"}</Typography>
              <Switch
                checked={enabled}
                color="primary"
                edge="start"
                name="enabled"
                onChange={(e) => {
                  setEnabled(e.target.checked);
                }}
                sx={{ mr: 10 }}
              />

              <Button
                key="test"
                color="primary"
                sx={{ mr: 1 }}
                variant="contained"
                onClick={handlePressExport}
                disabled={!(data?.tagMappings?.length > 0)}
              >
                엑셀 출력
              </Button>
              <LoadingButton
                variant="contained"
                loading={isUpdating}
                onClick={() => imageInputRef.current.click()}
                sx={{ mr: 1 }}
              >
                도서 목록 수정
              </LoadingButton>
              <LoadingButton
                color="warning"
                disabled={[1, 2, 3].includes(paramsParsed.tagId)}
                variant="contained"
                loading={isDeleting}
                sx={{ mr: 1 }}
                onClick={handleModify}
              >
                태그 수정
              </LoadingButton>

              <LoadingButton
                color="error"
                disabled={[1, 2, 3].includes(paramsParsed.tagId)}
                variant="contained"
                loading={isDeleting}
                onClick={handleDeleteTag}
              >
                태그 삭제
              </LoadingButton>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={6}>
              <Typography color="textPrimary">{"태그 명"}</Typography>

              <TextField
                id="standard-basic"
                fullWidth
                name="link"
                onChange={(e) => {
                  setTagName(e.target.value);
                }}
                value={tagname ? tagname : ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary">{"노출 태그 타입"}</Typography>

              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <MenuItem value={"ALL"}>전체</MenuItem>
                  <MenuItem value={"KOREAN"}>한글</MenuItem>
                  <MenuItem value={"ENGLISH"}>영어</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/*<Box mt={3} sx={{flex:1, flexDirection:'row'}}>*/}

          {/*    <TextField*/}
          {/*        id="standard-basic"*/}
          {/*        fullWidth*/}
          {/*        name="link"*/}
          {/*        // onChange={(e) => {setTagName(e.target.value)}}*/}
          {/*        value={tagname ? tagname : ''}*/}
          {/*        variant="outlined"*/}
          {/*    />*/}

          {/*    <Button*/}
          {/*        color="primary"*/}
          {/*        onClick={() => setIsModalOpen(true)}*/}
          {/*    >딥링크 생성</Button>*/}

          {/*</Box>*/}

          <Box mt={3} sx={{ flex: 1, flexDirection: "row" }}>
            <Box sx={{ pt: 5 }}>
              {!!imageurl && (
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img alt="Product" src={imageurl} width={360} />
                  </Box>
                </CardContent>
              )}
              <CardActions>
                <LoadingButton
                  color="primary"
                  fullWidth
                  variant="text"
                  onClick={() => imageUploadRef.current.click()}
                >
                  상단 이미지 업로드
                </LoadingButton>
              </CardActions>
              <input
                hidden
                type="file"
                accept="image/jpg, image/png, image/jpeg"
                onChange={(e) => {
                  handleChangeImage(e.target.files[0]);
                  imageUploadRef.current.value = null;
                }}
                ref={imageUploadRef}
              />
            </Box>
          </Box>
          <Box mt={3}>
            <Card>
              <DataGrid
                fixedHeader
                progressPending={isFetching}
                columns={columns}
                data={data?.tagMappings || []}
                title={
                  data ? data?.name + " - " + data?.tagMappings?.length : ""
                }
                paginationTotalRows={data?.tagMappings?.length}
              />
            </Card>
          </Box>
        </Container>
      </Box>
      <input
        hidden
        type="file"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={(e) => {
          handleUpdateTagMappings(e.target.files[0]);
          imageInputRef.current.value = null;
        }}
        ref={imageInputRef}
      />

      {/*<DeepLinkGenerationModal*/}
      {/*    open={isModalOpen}*/}
      {/*    onClose={() => setIsModalOpen(false)}*/}
      {/*    // onSubmit={handleChangeLink}*/}
      {/*    // link={link}*/}
      {/*/>*/}
    </>
  );
};

export default HomeTagEdit;
