export interface Config {
  url: string;
  method?: string;
  data?: any;
  params?: any;
  headers?: any;
  signal?: AbortSignal;
}

export interface Response<T> {
  url: string;
  type: string;
  ok: boolean;
  status?: number;
  data?: T;
  message?: string;
}
class InterceptorManager {
  handlers = new Map();

  use(
    key: string,
    fulfilled: (config: Config) => Config,
    rejected?: (parsedRes: Response<any>) => Response<any>
  ) {
    this.handlers.set(key, {
      fulfilled: fulfilled,
      rejected: rejected,
    });
  }

  eject(key: string) {
    if (this.handlers.has(key)) {
      this.handlers.delete(key);
    }
  }
}
export class Fetchn {
  default;
  interceptors: {
    request: InterceptorManager;
    response: InterceptorManager;
  };

  constructor() {
    this.interceptors = {
      request: new InterceptorManager(),
      response: new InterceptorManager(),
    };
  }

  async request<T>(config: Config) {
    let newConfig = {
      headers: {
        "Content-Type":
          config.data instanceof FormData
            ? "multipart/form-data"
            : "application/json",
        Accept: "application/json",
      },
      ...config,
    };

    this.interceptors.request.handlers.forEach(async (interceptor) => {
      newConfig = await interceptor.fulfilled(newConfig);
    });

    const url = newConfig.url + objectToQueryString(newConfig.params);
    const body =
      newConfig.data instanceof FormData
        ? newConfig.data
        : JSON.stringify(newConfig.data);

    let response: Response<T> = await fetchRequest(url, {
      headers: newConfig.headers,
      body,
      method: newConfig.method,
      signal: newConfig.signal,
    });

    if (!response.ok) {
      console.error(
        `**** Fetch Error : ${config.method || "GET"} ${config.url}\n`,
        JSON.stringify(response, null, 2)
      );
      if (response.type === "response") {
        this.interceptors.response.handlers.forEach(async (interceptor) => {
          response = await interceptor?.rejected?.(response);
        });
        throw response;
      }

      if (response.type === "request") {
        this.interceptors.request.handlers.forEach(async (interceptor) => {
          response = await interceptor?.rejected?.(response);
        });
        throw response;
      }
    }

    this.interceptors.response.handlers.forEach(async (interceptor) => {
      response = await interceptor?.fulfilled?.(response);
    });

    return response;
  }
}

const fetchRequest = async (url, { headers, body, method, signal }) => {
  try {
    const res = await fetch(url, {
      method: method,
      body: body,
      headers: headers,
      signal,
    });
    const data = await res.json().catch(() => {});

    return {
      url: url,
      type: "response",
      ok: res.ok,
      status: res.status,
      data: data,
    };
  } catch (err) {
    return {
      url: url,
      type: "request",
      ok: false,
      message: err.message,
    };
  }
};

const objectToQueryString = (obj) => {
  const keyValuePairs = [];
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      keyValuePairs.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      );
    }
  }
  return keyValuePairs.length > 0 ? "?" + keyValuePairs.join("&") : "";
};

const fetchn = new Fetchn();

export default fetchn;
