import { Link as RouterLink, useLocation } from "react-router-dom";
import type { Theme } from "@mui/material";
import { Avatar, Box, Divider, Drawer, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookIcon from "@mui/icons-material/Book";
import WorkIcon from "@mui/icons-material/Work";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SecurityIcon from "@mui/icons-material/Security";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import useAuth from "hooks/useAuth";
import UsersIcon from "icons/Users";
import Logo from "./components/Logo";
import NavSection from "./components/NavSection";
import Scrollbar from "./components/Scrollbar";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: "",
    items: [
      {
        title: "대시보드",
        path: "/",
        icon: <DashboardIcon fontSize="small" />,
      },
      {
        title: "도서관",
        path: "/libraries",
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: "도서관 목록",
            path: "/libraries",
          },
          {
            title: "최근 장바구니 등록 도서관 목록",
            path: "/libraries/cart/recent-register",
          },
          {
            title: "연락처 인증 이력 목록",
            path: "/libraries/certification/history",
          },
          {
            title: "정기 캐시 지급 관리 목록",
            path: "/libraries/regular-cash-payment-management",
          },
        ],
      },
      {
        title: "도서",
        path: "/books",
        icon: <BookIcon />,
        children: [
          {
            title: "도서 목록",
            path: "/books",
          },
          {
            title: "도서 목록(엑셀 검색)",
            path: "/books/excel-search",
          },
          {
            title: "카테고리 변경 요청",
            path: "/books/categories/change-request",
          },
          {
            title: "해시 태그 요청 목록",
            path: "/book-hash-tag",
          },
          {
            title: "위탁 가능 도서 목록",
            path: "/books/consignment-available/search",
          },
          {
            title: "위탁 불가 도서 목록",
            path: "/books/consignment-restriction/search",
          },
          {
            title: "도서 입고 요청",
            path: "/books/book-store-request",
          },
          {
            title: "추천의 글 목록",
            path: "/books/book-recommend",
          },
          {
            title: "서평 목록",
            path: "/contents/content-read-review",
          },
          {
            title: "독서 노트 목록",
            path: "/contents/reading-notes",
          },
          {
            title: "학교 권장 도서 신청",
            path: "/books/school-recommended-request",
          },
          {
            title: "주문 필요 도서 목록",
            path: "/books/need-store",
          },
          {
            title: "교보 ID 가져오기",
            path: "/books/get-kyoboid",
          },
          {
            title: "도서 입고 처리",
            path: "/books/book-store-process",
          },
          {
            title: "입고 종합 정보 목록",
            path: "/books/book-store",
          },
        ],
      },
      {
        title: "재고",
        path: "/stocks",
        icon: <WorkIcon />,
        children: [
          {
            title: "재고",
            path: "/stocks",
          },
          {
            title: "재고 서가번호 업로드",
            path: "/stocks/woodo-section-code-upload",
          },
          {
            title: "우도코드 발급",
            path: "/stocks/woodo-code-generate",
          },
          {
            title: "재고 판매 여부 설정",
            path: "/stocks/edit-sellable",
          },
          {
            title: "외부 판매 재고 목록",
            path: "/stocks/external-stocks",
          },
        ],
      },
      {
        title: "위탁",
        path: "/consignments",
        icon: <InventoryIcon />,
        children: [
          {
            title: "위탁",
            path: "/consignments",
          },
          {
            title: "이벤트용 위탁",
            path: "/consignments/event",
          },
          {
            title: "반환 요청",
            path: "/consignments/return-requests",
          },
        ],
      },
      {
        title: "주문",
        path: "/orders",
        icon: <ShoppingBasketIcon />,
        children: [
          {
            title: "주문",
            path: "/orders",
          },
          {
            title: "비회원 주문",
            path: "/orders/nonmember-orders",
          },
          {
            title: "외부 구매 주문",
            path: "/orders/external-orders",
          },
          {
            title: "외부 구매 주문 업로드",
            path: "/orders/external-order-process",
          },
          {
            title: "도서 분배",
            path: "/orders/ordernotcollected",
          },
          {
            title: "구매 도서 분배",
            path: "/orders/purchase-orders-not-collected",
          },
          {
            title: "연체 주문",
            path: "/overdue-orders",
          },
          {
            title: "가도서 주문 목록",
            path: "/orders/ordered-fake-stock/list",
          },
          {
            title: "입고 예정 도서 목록",
            path: "/orders/external-expect-store-books",
          },
          {
            title: "배송 특이사항 목록",
            path: "/orders/delivery-events",
          },
        ],
      },
      {
        title: "배송",
        path: "/deliveries",
        icon: <LocalShippingIcon />,
        children: [
          {
            title: "발송 운송장 출력",
            path: "/deliveries/send",
          },
          {
            title: "발송 운송장 업로드(롯데)",
            path: "/deliveries/send/upload",
          },
          {
            title: "회수 운송장 출력(롯데)",
            path: "/deliveries/return",
          },
          {
            title: "회수 운송장 업로드(롯데)",
            path: "/deliveries/return/upload",
          },
          {
            title: "배송불가일 관리",
            path: "deliveries/holiday",
          },
          {
            title: "도서 분배 목록",
            path: "/deliveries/division",
          },
        ],
      },
      {
        title: "예치금",
        path: "/deposits",
        icon: <LocalAtmIcon />,
        children: [
          {
            title: "예치금 정산",
            path: "/deposits/withdrawals",
          },
          {
            title: "제휴 도서관 예치금 분배율 관리",
            path: "/deposits/ratio/management",
          },
        ],
      },
      {
        title: "태그",
        path: "/tag",
        icon: <LocalOfferIcon />,
        children: [
          {
            title: "managed 태그",
            path: "/tag/tagmanaged",
          },
          {
            title: "custom 태그",
            path: "/tag/tagcustom",
          },
          {
            title: "series 태그",
            path: "/tag/tagseries",
          },
          {
            title: "events 태그",
            path: "/tag/tagevents",
          },
          {
            title: "author 태그",
            path: "/tag/tagauthor",
          },
        ],
      },
      {
        title: "배너관리",
        path: "/marketing",
        icon: <ViewCarouselIcon />,
        children: [
          {
            title: "앱 배너",
            path: "/marketing/banners",
          },
          {
            title: "웹 배너",
            path: "/marketing/web-banners",
          },
          {
            title: "파트너 도서관 배너",
            path: "/marketing/partner-library-banners",
          },
        ],
      },
      {
        title: "마케팅",
        path: "/marketing",
        icon: <NotificationsIcon />,
        children: [
          {
            title: "이벤트 페이지 목록",
            path: "/marketing/eventPages",
          },
          {
            title: "앱 푸시",
            path: "/marketing/eventPushes",
          },
          {
            title: "할인 이벤트 목록",
            path: "/marketing/promotions",
          },
          {
            title: "포인트 일괄 지급",
            path: "/marketing/eventPoints",
          },
          {
            title: "쿠폰 목록",
            path: "/marketing/coupons",
          },
          {
            title: "외부 쿠폰 목록",
            path: "/marketing/external-coupons",
          },
          {
            title: "래플 이벤트 목록",
            path: "/marketing/raffle-events",
          },
        ],
      },
      {
        title: "관리자",
        path: "/admin",
        icon: <SecurityIcon />,
        children: [
          {
            title: "공지사항",
            path: "/admin/notices",
          },
          {
            title: "관리자",
            path: "/admin/admins",
          },
          {
            title: "제휴업체 목록",
            path: "/admin/affiliated-company",
          },
        ],
      },
      // {
      //   title: 'MVP',
      //   path: '/mvp',
      //   icon: <Warehouse/>,
      //   children: [
      //     {
      //       title: '상품 목록',
      //       path: '/mvp/products'
      //     },
      //     {
      //       title: '중고 재고 목록',
      //       path: '/mvp/used-stocks'
      //     },
      //     {
      //       title: '주문 목록',
      //       path: '/mvp/orders'
      //     }
      //   ]
      // }
    ],
  },
];

const DashboardSidebar: React.FC<DashboardSidebarProps> = ({
  onMobileClose,
  openMobile,
}) => {
  const location = useLocation();
  const { authData } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const content = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: { lg: "none", xs: "flex" },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/dashbaord">
            <Logo sx={{ height: 40, width: 40 }} />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <Avatar src={null} sx={{ height: 48, width: 48 }} />
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {authData?.adminName}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {authData?.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{ "& + &": { mt: 3 } }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: { backgroundColor: "background.paper", width: 280 },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
