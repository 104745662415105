import React from "react";
import OrderChart from "./OrderChart";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardHeader, Divider } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles({
  root: {},
  chart: {
    height: 400,
  },
});

const Order = ({ dashboardinfo }: { dashboardinfo: any }) => {
  let deliverycount = [];
  let deliverydate = [];
  dashboardinfo.deliveryList.map((info) => {
    deliverycount = [...deliverycount, info.count];
    deliverydate = [...deliverydate, info.date];
  });

  const classes = useStyles();
  const stats = {
    thisYear: deliverycount,
  };
  const labels = deliverydate;

  return (
    <Card>
      <CardHeader title="출고 주문수" />
      <Divider />
      <PerfectScrollbar>
        <Box pt={4} pr={2} pl={2}>
          <OrderChart className={classes.chart} data={stats} labels={labels} />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default Order;
