import React from "react";
import type { FC } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { colors, Fade } from "@mui/material";
import numeral from "numeral";

interface ChartProps {
  className?: string;
  data: any[];
  labels: string[];
}

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
});

const PaymentChart: FC<ChartProps> = ({
  className,
  data: dataProp,
  labels,
  ...rest
}) => {
  const classes = useStyles();
  // const theme: Theme = useTheme();

  const data = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);

    gradient.addColorStop(0, "#5850EC");
    gradient.addColorStop(0.9, "rgba(255,255,255,0)");
    gradient.addColorStop(1, "rgba(255,255,255,0)");

    return {
      datasets: [
        {
          data: dataProp,
          backgroundColor: gradient,
          borderColor: "#5850EC",
          pointBorderColor: "white",
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: "#5850EC",
        },
      ],
      labels,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            fontColor: colors.blueGrey[600],
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: colors.blueGrey[100],
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: colors.blueGrey[100],
          },
          ticks: {
            padding: 20,
            fontColor: colors.blueGrey[600],
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 7,
            callback: (value: number) =>
              value > 0 ? `${numeral(value).format("0,0[.]00")} 원` : value,
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: colors.blueGrey[100],
      backgroundColor: "#f4f6f8",
      titleFontColor: colors.blueGrey[900],
      bodyFontColor: colors.blueGrey[600],
      footerFontColor: colors.blueGrey[600],
      callbacks: {
        title: () => {},
        label: (tooltipItem: any) => {
          let label = `매출 ${numeral(tooltipItem.yLabel).format("0,0[.]00")}`;

          if (tooltipItem.yLabel > 0) {
            label += "원";
          }

          return label;
        },
      },
    },
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Line data={data} options={options} />
    </div>
  );
};

PaymentChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
};

export default PaymentChart;
