import { useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import PathHelper from "utils/PathHelper";

const useSearchParamsState = <T extends { [key: string]: any }>(options: {
  defaultValues?: Partial<T>;
  parse?: Partial<Record<keyof T, (value: string) => any>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams(
    options?.defaultValues
  );
  const optionsRef = useRef(options);

  const handleChangeSearchParams = useCallback(
    (data: T, options?: { replace: boolean }) => {
      setSearchParams(
        PathHelper.convertObjectToUrlParams(data),
        options || { replace: true }
      );
    },
    [setSearchParams]
  );

  const searchParamsObj = useMemo(() => {
    const searchParamsObj: T = {} as T;
    for (const [key, value] of searchParams) {
      const parsedValue = optionsRef.current?.parse?.[key]?.(value);
      searchParamsObj[key as keyof T] =
        parsedValue !== undefined ? parsedValue : value;
    }
    return searchParamsObj;
  }, [searchParams, optionsRef]);

  return {
    searchParams: searchParamsObj,
    setSearchParams: handleChangeSearchParams,
  };
};

export default useSearchParamsState;
