import React, { createContext, useCallback, useEffect, useState } from "react";

import AuthApi from "apis/Auth";

import { Auth } from "types/Account";
import fetchn from "utils/Fetchn";
import StorageKey from "constants/StorageKey";
import Storage from "utils/Storage";
interface Context {
  authData: Auth;
  isInitialized: boolean;
  signIn: (idToken: string) => void;
  signOut: () => void;
}

export const AuthContext = createContext<Context>({
  authData: null,
  isInitialized: false,
  signIn: () => {},
  signOut: () => {},
});

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState<Auth>();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (authData?.expiredAt) {
      // const expiredIn = new Date(authData.expiredAt).getTime() - new Date().getTime();
      // const handleAppStateChange = state => {
      //     if (state === 'active' && expiredIn < 43200 * 1000) {
      //         AuthApi().refreshToken().then(data => {
      //             setAuthData(data);
      //             Storage.setItem(StorageKey.authData, data);
      //             fetchn.interceptors.request.use('Authorization', config => {
      //                 config.headers.Authorization = `Bearer ${data?.token}`;
      //                 return config;
      //             })
      //         });
      //     }
      // }
    }
  }, [authData]);

  const signIn = async (idToken: string) => {
    const authData = await AuthApi().signIn(idToken);
    setAuthData(authData);
    Storage.setItem(StorageKey.authData, authData);
    fetchn.interceptors.request.use("Authorization", (config) => {
      config.headers.Authorization = `Bearer ${authData?.token}`;
      return config;
    });
    fetchn.interceptors.response.use(
      "TokenExpired",
      (config) => config,
      (res) => {
        if (res.status === 401) {
          signOut();
        } else if (res.status === 403) {
          alert("접근 권한이 없습니다.");
          signOut();
        }
        return res;
      }
    );
  };

  const signOut = async () => {
    setAuthData(null);
    Storage.removeItem(StorageKey.authData);
    fetchn.interceptors.request.eject("Authorization");
    fetchn.interceptors.response.eject("TokenExpired");
  };

  const loadAuthData = useCallback(async () => {
    const data: Auth = Storage.getItem(StorageKey.authData);
    if (data?.token) {
      fetchn.interceptors.request.use("Authorization", (config) => {
        config.headers.Authorization = `Bearer ${data?.token}`;
        return config;
      });
      fetchn.interceptors.response.use(
        "TokenExpired",
        (config) => config,
        (res) => {
          if (res.status === 401) {
            signOut();
          } else if (res.status === 403) {
            alert("접근 권한이 없습니다.");
            signOut();
          }
          return res;
        }
      );
    }
    setAuthData(data);
    setIsInitialized(true);
    return data;
  }, []);

  useEffect(() => {
    loadAuthData();
  }, [loadAuthData]);

  return (
    <AuthContext.Provider
      value={{
        authData,
        isInitialized,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
