class PathHelper {
  static convertObjectToUrlParams(data: Record<string, any>) {
    const transformedObject: Record<string, string> = {};
    for (const key in data) {
      const value = data[key];
      if (typeof value === "object" && value?.length > 0) {
        transformedObject[key] = value.join(",");
      } else if (data[key] !== undefined && data[key] !== null) {
        transformedObject[key] = value;
      }
    }
    return transformedObject;
  }
}

export default PathHelper;
