import { ImageType, Platform } from "constants/Server";
import fetchn from "utils/Fetchn";

const CommonApi = (signal?) => {
  return {
    uploadImage: async ({
      image,
      imageType,
    }: {
      image: File;
      imageType: ImageType;
    }) => {
      // POST /manager/v0/common/images
      let formData = new FormData();

      formData.append("image", image);

      const { data } = await fetchn.request<{ url: string }>({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/common/images`,
        headers: {},
        params: {
          imageType,
        },
        data: formData,
        signal,
      });
      return data;
    },
  };
};

export default CommonApi;
