import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { StockStatus } from "constants/Server";
import { TextStockStatus } from "constants/Local";

const optionsStockStatus = [
  StockStatus.IDLE,
  StockStatus.LENT,
  StockStatus.PACKED,
  StockStatus.PENDING_RETURN_CONFIRM,
  StockStatus.RESERVED,
  StockStatus.BE_SOLD,
  StockStatus.SOLD,
  StockStatus.CLOSED,
  StockStatus.LOST,
  StockStatus.DISCARDED,
];

const StockFilterModal = ({
  open,
  values,
  onClose,
  onSubmit,
}: {
  open: boolean;
  values: {
    stockStatuses: StockStatus[];
    sellableInShop: boolean;
    consigned: boolean;
    payback: boolean;
    shopPurchased: boolean;
    excludeFake: boolean;
    isSpecial: boolean;
    sellable: boolean;
  };
  onClose: () => void;
  onSubmit: (values: {
    consigned: boolean;
    sellableInShop: boolean;
    stockStatuses: StockStatus[];
    payback: boolean;
    shopPurchased: boolean;
    excludeFake: boolean;
    isSpecial: boolean;
    sellable: boolean;
  }) => void;
}) => {
  const [statuses, setStatuses] = useState<StockStatus[]>(
    values.stockStatuses || []
  );
  const [consigned, setConsigned] = useState(values.consigned);
  const [sellableInShop, setSellableInShop] = useState(values.sellableInShop);
  const [payback, setPayback] = useState(values.payback);
  const [shopPurchased, setShopPurchased] = useState(values.shopPurchased);
  const [excludeFake, setExcludeFake] = useState(values.excludeFake);
  const [isSpecial, setIsSpecial] = useState(values.isSpecial);
  const [sellable, setSellable] = useState(values.sellable);

  useEffect(() => {
    setStatuses(values.stockStatuses || []);
    setConsigned(values.consigned);
    setSellableInShop(values.sellableInShop);
    setPayback(values.payback);
    setShopPurchased(values.shopPurchased);
    setExcludeFake(values.excludeFake);
    setIsSpecial(values.isSpecial);
    setSellable(values.sellable);
  }, [values]);

  const handleSubmit = () => {
    onClose();
    onSubmit({
      consigned,
      sellableInShop,
      stockStatuses: statuses,
      payback,
      shopPurchased,
      excludeFake,
      isSpecial,
      sellable,
    });
  };

  const handleSelectStatus = (_status: StockStatus) => {
    if (statuses.includes(_status)) {
      setStatuses(statuses.filter((status) => status !== _status));
    } else {
      setStatuses([...statuses, _status]);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">필터</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 0 }} component="fieldset" variant="standard">
            <FormLabel component="legend">재고 상태</FormLabel>
            <FormGroup>
              {optionsStockStatus.map((stockStatus) => {
                return (
                  <FormControlLabel
                    key={stockStatus}
                    control={
                      <Checkbox
                        checked={statuses.includes(stockStatus)}
                        onChange={() => handleSelectStatus(stockStatus)}
                        name={stockStatus}
                      />
                    }
                    label={TextStockStatus[stockStatus]}
                  />
                );
              })}
            </FormGroup>

            <FormLabel component="legend">기타 상태</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={consigned}
                  onChange={() => setConsigned(!consigned)}
                  name="consigned"
                />
              }
              label={"위탁"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sellableInShop}
                  onChange={() => setSellableInShop(!sellableInShop)}
                  name="sellableInShop"
                />
              }
              label={"우도샵 판매 여부"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shopPurchased}
                  onChange={() => setShopPurchased(!shopPurchased)}
                  name="shopPurchased"
                />
              }
              label={"우도샵 구매"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={excludeFake}
                  onChange={() => setExcludeFake(!excludeFake)}
                  name="excludeFake"
                />
              }
              label={"가재고 제외"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSpecial}
                  onChange={() => setIsSpecial(!isSpecial)}
                  name="isSpecial"
                />
              }
              label={"특이 사항 존재 여부"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sellable}
                  onChange={() => setSellable(!sellable)}
                  name="sellable"
                />
              }
              label={"판매 가능 여부"}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            수정
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StockFilterModal;
