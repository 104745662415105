enum QueryKey {
  admins = "admins",
  admin = "admin",
  additionalPayments = "additionalPayments",
  addresses = "addresses",
  coordinates = "coordinates",
  banner = "banner",
  banners = "banners",
  webBanners = "webBanners",
  book = "book",
  booksPaging = "booksPaging",
  bookConsignmentRegistrationsPaging = "bookConsignmentRegistrationsPaging",
  bookConsignmentRegistration = "bookConsignmentRegistration",
  bookConsignmentRegistrationEvent = "bookConsignmentRegistrationEvent",
  bookNeedStore = "bookNeedStore",
  categoryChangeRequests = "categoryChangeRequests",
  classCategories = "classCategories",
  depositsPaging = "depositsPaging",
  depositsWithdrawalsPaging = "depositsWithdrawalsPaging",
  depositTotal = "depositTotal",
  librariesPaging = "librariesPaging",
  library = "library",
  libraryNames = "libraryNames",
  notice = "notice",
  notices = "notices",
  notificationsPaging = "notificationsPaging",
  order = "order",
  orderItems = "orderItems",
  orderLogs = "orderLogs",
  ordersPaging = "ordersPaging",
  nonmemberOrdersPaging = "nonmemberOrdersPaging",
  nonmemberOrder = "nonmemberOrder",
  ordersGathered = "ordersGathered",
  ordersReturnRequested = "ordersReturnRequested",
  orderPurchase = "orderPurchase",
  pointsPaging = "pointsPaging",
  pointTotal = "pointTotal",
  stock = "stock",
  stocksPaging = "stocksPaging",
  subjectCategories = "subjectCategories",
  cartItems = "cartItems",
  tags = "tags",
  managedtags = "managedtags",
  customtags = "customtags",
  eventtags = "eventtags",
  hometags = "hometags",
  alltags = "alltags",
  tag = "tag",
  hometag = "hometag",
  eventPage = "eventPage",
  eventPages = "eventPages",
  eventPush = "eventPush",
  eventPushes = "eventPushes",
  promotions = "promotions",
  promotion = "promotions",
  prizes = "prizes",
  eventPoints = "eventPoints",
  eventPoint = "eventPoint",
  certificationLogs = "certificationLogs",
  contentRecommends = "contentRecommends",
  contentRecommend = "contentRecommend",

  //add
  dashboardInfo = "dashboardInfo",
  dashboardLastOrder = "dashboardLastOrder",
  dashboardBestLibrary = "dashboardBestLibrary",
  holiday = "holiday",
  dailyOperation = "dailyOperation",
  contentItem = "contentItem",
  seriesTitle = "seriesTitle",
  overdueOrders = "overdueOrders",
  coupons = "coupons",
  coupon = "coupon",
  consignmentAvailableBooks = "consignmentAvailableBooks",
  consignmentRestrictionBooks = "consignmentRestrictionBooks",
  cartRecentRegisterLibraries = "cartRecentRegisterLibraries",
  couponUsedLibraries = "couponUsedLibraries",
  bookstoreRequests = "bookstoreRequests",
  orderedFakeStockAmount = "orderedFakeStockAmount",
  bookHashTagList = "bookHashTagList",
  bookHashTagRankList = "bookHashTagRankList",
  getContentReadReviewList = "getContentReadReviewList",
  bannerGroups = "bannerGroups",
  stockMemos = "stockMemos",
  stockMemo = "stockMemo",
  orderMemos = "orderMemos",
  orderMemo = "orderMemo",
  libraryMemos = "libraryMemos",
  libraryMemo = "libraryMemo",
  recommendRewardManagement = "recommendRewardManagement",
  depositRatioManagement = "depositRatioManagement",
  certificationHistoryList = "certificationHistoryList",
  raffleEventList = "raffleEventList",
  raffleEvent = "raffleEvent",
  schoolRecommendedBookRequestList = "schoolRecommendedBookRequestList",
  deliveryEventList = "deliveryEventList",
  deliveryEvent = "deliveryEvent",
  cashTotal = "cashTotal",
  cashPaging = "cashPaging",
  regularCashPaymentManagementPaging = "regularCashPaymentManagementPaging",
  regularCashPaymentManagement = "regularCashPaymentManagement",
  regularCashPaymentItemPaging = "regularCashPaymentItemPaging",
  partnerLibraryBannerList = "partnerLibraryBannerList",
  affiliatedCompanyPaging = "affiliatedCompanyPaging",
  affiliatedCompany = "affiliatedCompany",
  affiliatedCompanyMemberPaging = "affiliatedCompanyMemberPaging",
  companyName = "companyName",
  externalCouponList = "externalCouponList",
  externalOrdersPaging = "externalOrdersPaging",
  externalOrder = "externalOrder",
  getReadingNotesList = "getReadingNotesList",
  bookReturnRequestPaging = "bookReturnRequestPaging",
  bookReturnRequest = "bookReturnRequest",
  stockLogs = "stockLogs",
  externalStockPaging = "externalStockPaging",
  externalExpectStoreBookPaging = "externalExpectStoreBookPaging",
  bookStoreProcessPaging = "bookStoreProcessPaging",
  externalBookStorePaging = "externalBookStorePaging",
  externalBookStoreItemList = "externalBookStoreItemList",
  externalBookStoreItemHistoryList = "externalBookStoreItemHistoryList",
  adminWorkList = "adminWorkList",

  // mvp
  productPaging = "productPaging",
  product = "product",
  productAdditionalFee = "productAdditionalFee",
  mvpOrderPaging = "mvpOrderPaging",
  mvpOrder = "mvpOrder",
  mvpOrderLogs = "mvpOrderLogs",
  mvpUsedStockPaging = "mvpUsedStockPaging",
  mvpUsedStock = "mvpUsedStock",
}

export default QueryKey;
