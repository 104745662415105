import {
  AdminBookSearchSort,
  BookStoreRequestStatus,
  ConsignmentRegistrationStatus,
  ExternalStoreTargetType,
  ExternalStoreType,
  Language,
  OrderCancelStatus,
  PublishType,
  ReceptionType,
  ReturnType,
  SendDeliveryStatus,
} from "constants/Server";
import { InfiniteList } from "types/Common";
import {
  AdminBookSummary,
  AdminSimpleBook,
  BookReturnRequestDetailResDto,
  BookReturnRequestSearchItemDto,
  BookStoreRequest,
  ConsignmentAvailableBook,
  ConsignmentRestrictionBook,
  ExternalExpectStoreBookSearchResDto,
  ExternalStoreBookItemHistoryResDto,
  ExternalStoreBookItemResDto,
  ExternalStoreBookSearchResDto,
  ExternalStoreHistorySearchResDto,
  NeedStoreBook,
  RemoteBook,
  RemoteBookCategoryChangeRequest,
  RemoteConsignmentRegistrationDelivery,
  RemoteConsignmentRegistrationEvent,
  RemoteConsignmentRegistrationWithItems,
} from "types/Content";
import fetchn from "utils/Fetchn";
import { ExternalOrderSearchItemDto } from "../types/Order";
import { RemoteAddress } from "../types/Library";

const BookApi = (signal?) => {
  return {
    getBook: async (bookId: number) => {
      // GET /manager/v0/books/{id}
      const { data } = await fetchn.request<RemoteBook>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/books/${bookId}`,
        signal,
      });
      return data;
    },
    getBooks: async ({
      page,
      size,
      publishType,
      title,
      isbn,
      adminChecked,
      sort,
    }: {
      page: number;
      size: number;
      publishType: PublishType;
      title: string;
      isbn: string;
      adminChecked?: boolean;
      sort: AdminBookSearchSort;
    }) => {
      // GET /manager/v0/books
      const { data } = await fetchn.request<InfiniteList<AdminBookSummary>>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/books`,
        params: {
          keyword: title,
          "pageRequest.page": page,
          "pageRequest.size": size,
          publishType,
          isbn,
          adminChecked,
          sort,
        },
        signal,
      });
      return data;
    },
    editBook: async ({
      bookId,
      data: {
        authorNames,
        classCategoryId,
        description,
        webDescription,
        imageUrl,
        hdImageUrl,
        isbn,
        issn,
        language,
        prize,
        publishType,
        publishedAt,
        publisherName,
        retailPrice,
        sellingPrice,
        setIsbn,
        subTitle,
        subjectCategoryId,
        tagIds,
        title,
        seriesTitle,
        estimateProfitAmountManual,
        youtubeLink,
        quantity,
        hashTags,
        kyoboBookId,
        previewReset,
      },
    }: {
      bookId: number;
      data: {
        authorNames?: string;
        classCategoryId?: string;
        description?: string;
        webDescription?: string;
        imageUrl?: string;
        hdImageUrl?: string;
        isbn?: string;
        issn?: string;
        language?: Language;
        prize?: string;
        publishType?: PublishType;
        publishedAt?: string;
        publisherName?: string;
        retailPrice?: number;
        sellingPrice?: number;
        setIsbn?: string;
        subTitle?: string;
        subjectCategoryId?: string;
        tagIds?: number[];
        title?: string;
        seriesTitle?: string;
        estimateProfitAmountManual?: number;
        youtubeLink?: string;
        quantity?: number;
        hashTags?: string[];
        kyoboBookId?: string;
        previewReset?: boolean;
      };
    }) => {
      // PATCH /manager/v0/books/{id}
      await fetchn.request({
        method: "PATCH",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/books/${bookId}`,
        data: {
          authorNames,
          classCategoryId,
          description,
          webDescription,
          imageUrl,
          hdImageUrl,
          isbn,
          issn,
          language,
          prize,
          publishType,
          publishedAt,
          publisherName,
          retailPrice,
          sellingPrice,
          setIsbn,
          subTitle,
          subjectCategoryId,
          tagIds,
          title,
          seriesTitle,
          estimateProfitAmountManual,
          youtubeLink,
          quantity,
          hashTags,
          kyoboBookId,
          previewReset,
        },
      });
    },
    addBook: async ({
      authorNames,
      classCategoryId,
      description,
      webDescription,
      imageUrl,
      isbn,
      issn,
      language,
      prize,
      publishType,
      publishedAt,
      publisherName,
      retailPrice,
      setIsbn,
      subTitle,
      subjectCategoryId,
      tagIds,
      title,
      seriesTitle,
      estimateProfitAmountManual,
      youtubeLink,
      quantity,
      hashTags,
      kyoboBookId,
    }: {
      authorNames: string;
      classCategoryId: string;
      description: string;
      webDescription: string;
      imageUrl: string;
      isbn: string;
      issn: string;
      language: Language;
      prize?: string;
      publishType: PublishType;
      publishedAt: string;
      publisherName: string;
      retailPrice: number;
      setIsbn: string;
      subTitle: string;
      subjectCategoryId: string;
      tagIds?: string[];
      title: string;
      seriesTitle?: string;
      estimateProfitAmountManual: number;
      youtubeLink: string;
      quantity: number;
      hashTags: string[];
      kyoboBookId: string;
    }) => {
      // POST /manager/v0/books
      await fetchn.request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/books`,
        data: {
          authorNames,
          classCategoryId,
          description,
          webDescription,
          imageUrl,
          isbn,
          issn,
          language,
          prize,
          publishType,
          publishedAt,
          publisherName,
          retailPrice,
          setIsbn,
          subTitle,
          subjectCategoryId,
          tagIds,
          title,
          seriesTitle,
          estimateProfitAmountManual,
          youtubeLink,
          quantity,
          hashTags,
          kyoboBookId,
        },
      });
    },
    getCategoryChangeRequests: async ({
      page,
      size,
      processed,
    }: {
      page: number;
      size: number;
      processed: boolean;
    }) => {
      // GET /manager/v0/books/categories/change-request
      const { data } = await fetchn.request<
        InfiniteList<RemoteBookCategoryChangeRequest>
      >({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/categories/change-request`,
        params: {
          "page.page": page,
          "page.size": size,
          processed,
        },
        signal,
      });
      return data;
    },
    editCategoryChangeRequests: async (
      data: { id: number; processed: boolean }[]
    ) => {
      // PATCH /manager/v0/books/categories/change-request
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/categories/change-request`,
        data,
      });
    },
    getBookConsignmentRegistrations: async ({
      libraryId,
      page,
      size,
      receptionTypes,
      statuses,
    }: {
      libraryId?: number;
      page?: number;
      size?: number;
      receptionTypes?: ReceptionType[];
      statuses?: ConsignmentRegistrationStatus[];
    }) => {
      // POST /manager/v0/books/consignment/registration/search
      const { data } = await fetchn.request<
        InfiniteList<RemoteConsignmentRegistrationDelivery>
      >({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment/registration/search`,
        data: {
          libraryId,
          page: {
            page,
            size,
          },
          receptionTypes,
          statuses,
        },
      });
      return data;
    },
    getBookConsignmentRegistrationsEvent: async ({
      from,
      to,
    }: {
      from: string;
      to: string;
    }) => {
      // GET /manager/v0/books/consignment/registration/search/event
      const { data } = await fetchn.request<
        RemoteConsignmentRegistrationEvent[]
      >({
        method: "GET",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment/registration/search/event`,
        params: {
          from,
          to,
        },
      });
      return data;
    },
    getBookConsignmentRegistrationWithItems: async (consignmentId: number) => {
      // GET /manager/v0/books/consignment/registration/{id}
      const { data } =
        await fetchn.request<RemoteConsignmentRegistrationWithItems>({
          url:
            process.env.REACT_APP_SERVER_URI +
            `/manager/v0/books/consignment/registration/${consignmentId}`,
        });
      return data;
    },
    rejectDeliveryReception: async ({
      registrationId,
      status,
    }: {
      registrationId: number;
      status: ConsignmentRegistrationStatus;
    }) => {
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment/registration/${registrationId}/status`,
        data: { status },
        signal,
      });
    },
    updateBookConsignmentRegistrationsStatuses: async ({
      ids,
      consignmentList,
      status,
    }: {
      ids?: null | number[];
      consignmentList?: null | Array<{
        consignmentNo: string;
        deliveryCode: string;
      }>;
      status: ConsignmentRegistrationStatus;
    }) => {
      // PATCH /manager/v0/books/consignment/registration/status
      const { data } = await fetchn.request<string[]>({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment/registration/status`,
        data: {
          ids,
          consignmentList,
          status,
        },
      });
      return data;
    },
    cancelBookConsignmentRegistrations: async (registrationIds: number[]) => {
      // PATCH /manager/v0/books/consignment/registration/status
      const { data } = await fetchn.request<{ hasError: boolean }>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment/registration/cancel`,
        data: registrationIds,
      });
      return data;
    },
    getPrizes: async () => {
      // GET /manager/v0/books/prizes
      const { data } = await fetchn.request<{
        domesticPrizes: { id: string; name: string }[];
        foreignPrizes: { id: string; name: string }[];
      }>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/books/prizes`,
      });
      return data;
    },
    getBooksByExcel: async ({
      bookIds,
      isbnList,
    }: {
      bookIds?: number[];
      isbnList?: string[];
    }) => {
      // POST /manager/v0/books/search/excel
      const { data } = await fetchn.request<AdminSimpleBook[]>({
        url:
          process.env.REACT_APP_SERVER_URI + `/manager/v0/books/search/excel`,
        method: "POST",
        data: {
          bookIds,
          isbnList,
        },
        signal,
      });
      return data;
    },
    getConsignmentAvailableBooks: async ({
      publishType,
      page,
      size,
    }: {
      publishType: PublishType;
      page: number;
      size: number;
    }) => {
      const { data } = await fetchn.request<
        InfiniteList<ConsignmentAvailableBook>
      >({
        // GET /manager/v0/books/search/consignment-available
        method: "GET",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/search/consignment-available`,
        params: {
          publishType,
          "page.page": page,
          "page.size": size,
        },
        signal,
      });

      return data;
    },
    addConsignmentAvailableBooks: async (
      bookIds: number[],
      publishType: PublishType
    ) => {
      await fetchn.request({
        // POST /manager/v0/books/consignment-restriction
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment-available`,
        data: {
          bookIds,
          publishType,
        },
        signal,
      });
    },
    removeConsignmentAvailableBooks: async (
      bookConsignmentAvailableIds: number[]
    ) => {
      await fetchn.request({
        // DELETE /manager/v0/books/consignment-restriction
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment-available`,
        params: { bookConsignmentAvailableIds },
        signal,
      });
    },
    getBookStoreRequestList: async ({
      page,
      size,
      status,
    }: {
      page: number;
      size: number;
      status: BookStoreRequestStatus;
    }) => {
      // GET /manager/v0/books/categories/change-request
      const { data } = await fetchn.request<InfiniteList<BookStoreRequest>>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/search/book-store-request`,
        params: {
          "page.page": page,
          "page.size": size,
          status,
        },
        signal,
      });
      return data;
    },
    removeBookStoreRequests: async (bookStoreRequestIds: number[]) => {
      await fetchn.request({
        // DELETE /manager/v0/books/book-store-request
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/book-store-request`,
        params: {
          bookStoreRequestIds,
        },
        signal,
      });
    },
    modifyStatusBookStoreRequests: async (
      bookStoreRequestIds: number[],
      status: BookStoreRequestStatus
    ) => {
      await fetchn.request({
        // POST /manager/v0/books/book-store-request
        method: "PUT",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/book-store-request`,
        data: {
          bookStoreRequestIds,
          status,
        },
        signal,
      });
    },
    bulkAddBook: async (
      data: {
        authorNames: string;
        classCategoryId: string;
        description: string;
        imageUrl: string;
        hdImageUrl: string;
        isbn: string;
        language: Language;
        publishType: PublishType;
        publishedAt: string;
        publisherName: string;
        retailPrice: number;
        subjectCategoryId: string;
        title: string;
        seriesTitle: string;
        quantity: number;
        kyoboBookId: string;
      }[]
    ) => {
      const { data: response } = await fetchn.request<
        { title: string; failReason: string }[]
      >({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/books/bulk-add`,
        data: { items: data },
        signal,
      });

      return response;
    },
    getNeedStoreBooks: async () => {
      // GET /manager/v0/books/search/need-store
      const { data } = await fetchn.request<NeedStoreBook[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/search/need-store`,
        signal,
      });
      return data;
    },
    getConsignmentRestrictionBooks: async (page: number, size: number) => {
      const { data } = await fetchn.request<
        InfiniteList<ConsignmentRestrictionBook>
      >({
        // GET /manager/v0/books/search/consignment-restriction
        method: "GET",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/search/consignment-restriction`,
        params: {
          "page.page": page,
          "page.size": size,
        },
        signal,
      });

      return data;
    },
    addConsignmentRestrictionBooks: async (
      data: { bookId: number; disabledWoodo: boolean }[]
    ) => {
      await fetchn.request({
        // POST /manager/v0/books/consignment-restriction
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment-restriction`,
        data: {
          bookConsignmentRestrictionList: data,
        },
        signal,
      });
    },
    removeConsignmentRestrictionBooks: async (
      bookConsignmentRestrictionIds: number[]
    ) => {
      await fetchn.request({
        // DELETE /manager/v0/books/consignment-restriction
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/consignment-restriction`,
        params: { bookConsignmentRestrictionIds },
        signal,
      });
    },

    getKyoboSearchCrawling: async (isbn) => {
      const { data } = await fetchn.request<any>({
        method: "GET",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/kyobo/autocomplete`,
        params: { isbn: isbn },
      });

      return data;
    },
    getExternalOrders: async ({
      page,
      size,
      sendDeliveryStatus,
      cancelStatus,
    }: {
      page: number;
      size: number;
      sendDeliveryStatus?: SendDeliveryStatus;
      cancelStatus?: OrderCancelStatus;
    }) => {
      // GET /manager/v0/external-orders
      const { data } = await fetchn.request<
        InfiniteList<ExternalOrderSearchItemDto>
      >({
        method: "GET",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/external-orders`,
        params: {
          cancelStatus,
          sendDeliveryStatus,
          "page.page": page,
          "page.size": size,
        },
        signal,
      });
      return data;
    },
    getBookReturnRequestList: async (params: {
      page: number;
      size: number;
      completed: boolean;
    }) => {
      // GET /manager/v0/books/return-request
      const { data } = await fetchn.request<
        InfiniteList<BookReturnRequestSearchItemDto>
      >({
        url:
          process.env.REACT_APP_SERVER_URI + `/manager/v0/books/return-request`,
        params: {
          "page.page": params.page,
          "page.size": params.size,
          completed: params.completed,
        },
        signal,
      });
      return data;
    },
    getBookReturnRequest: async (
      bookReturnRequestId: number,
      type: ReturnType
    ) => {
      // GET /manager/v0/books/return-request/{bookReturnRequestId}
      const { data } = await fetchn.request<BookReturnRequestDetailResDto>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/return-request/${bookReturnRequestId}`,
        params: { type },
        signal,
      });
      return data;
    },
    approveBookReturnRequestItem: async (
      bookReturnRequestId: number,
      bookReturnRequestItemId: number,
      type: ReturnType
    ) => {
      // PATCH /manager/v0/books/return-request/{bookReturnRequestId}/approve/{bookReturnRequestItemId}
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/return-request/${bookReturnRequestId}/approve/${bookReturnRequestItemId}`,
        params: { type },
        signal,
      });
    },
    editBookReturnRequest: async ({
      bookReturnRequestId,
      data,
    }: {
      bookReturnRequestId: number;
      data: {
        destination?: RemoteAddress;
        sendDeliveryCode?: string;
        type: ReturnType;
      };
    }) => {
      // PUT /manager/v0/books/return-request
      await fetchn.request({
        method: "PUT",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/books/return-request/${bookReturnRequestId}`,
        data: data,
        signal,
      });
    },
    getExternalExpectStoreBooks: async (page: number, size: number) => {
      // GET /manager/v0/external/expect-store-books
      const { data } = await fetchn.request<
        InfiniteList<ExternalExpectStoreBookSearchResDto>
      >({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/expect-store-books`,
        params: {
          "page.page": page,
          "page.size": size,
        },
        signal,
      });
      return data;
    },
    addExternalExpectStoreBookCs: async (data: {
      isbn: string;
      reason: string;
      quantity: number;
    }) => {
      // POST /manager/v0/external/expect-store-books/cs
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/expect-store-books/cs`,
        data: data,
        signal,
      });
    },
    addExternalExpectStoreBooksDirect: async (
      data: {
        isbn: string;
        quantity: number;
      }[]
    ) => {
      // POST /manager/v0/external/expect-store-books/direct
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/expect-store-books/direct`,
        data: data,
        signal,
      });
    },
    getBookStoreProcessList: async (params: {
      page: number;
      size: number;
      type: ExternalStoreType;
      date: string;
    }) => {
      // GET /manager/v0/external/store-books/store
      const { data } = await fetchn.request<
        InfiniteList<ExternalStoreHistorySearchResDto>
      >({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/store`,
        params: {
          "page.page": params.page,
          "page.size": params.size,
          type: params.type,
          date: params.date,
        },
        signal,
      });
      return data;
    },
    processBookStore: async (isbn: string) => {
      // POST /manager/v0/external/store-books/store
      const { data } = await fetchn.request<{ type: ExternalStoreType }>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/store`,
        data: { isbn },
        signal,
      });

      return data;
    },
    completeCsBookStore: async (externalStoreHistoryId: number) => {
      // PATCH /manager/v0/external/store-books/store-history/{externalStoreHistoryId}
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/store-history/${externalStoreHistoryId}`,
        signal,
      });
    },
    getBookStoreList: async (page: number, size: number) => {
      // GET /manager/v0/external/store-books
      const { data } = await fetchn.request<
        InfiniteList<ExternalStoreBookSearchResDto>
      >({
        url:
          process.env.REACT_APP_SERVER_URI + `/manager/v0/external/store-books`,
        params: {
          "page.page": page,
          "page.size": size,
        },
        signal,
      });
      return data;
    },
    addExpectStoreBooks: async (
      data: {
        isbn: string;
        quantity: number;
        type: ExternalStoreType;
        targetId: number;
        targetType: ExternalStoreTargetType;
      }[]
    ) => {
      // POST /manager/v0/external/store-books/expect
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/expect`,
        data,
        signal,
      });
    },
    addOrderedStoreBooks: async (
      data: {
        isbn: string;
        quantity: number;
      }[]
    ) => {
      // POST /manager/v0/external/store-books/ordered
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/ordered`,
        data,
        signal,
      });
    },
    getBookStoreItemList: async (date: string) => {
      // GET /manager/v0/external/store-books/items
      const { data } = await fetchn.request<ExternalStoreBookItemResDto[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/items`,
        params: {
          date,
        },
        signal,
      });
      return data;
    },
    getBookStoreItemHistoryList: async (params: {
      date: string;
      isbn: string;
    }) => {
      // GET /manager/v0/external/store-books/histories
      const { data } = await fetchn.request<
        ExternalStoreBookItemHistoryResDto[]
      >({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/histories`,
        params,
        signal,
      });
      return data;
    },
    deleteBookStoreProcessHistory: async (externalStoreHistoryId: number) => {
      // DELETE /manager/v0/external/store-books/store/{externalStoreHistoryId}
      await fetchn.request({
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external/store-books/store/${externalStoreHistoryId}`,
        signal,
      });
    },
  };
};

export default BookApi;
