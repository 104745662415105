import { Tag, TagWithTagMappings } from "types/Content";
import fetchn from "utils/Fetchn";
import { TagFilter } from "../constants/Server";

const TagApi = (signal?) => {
  return {
    //add managed 2022.10.21 hc
    getManagedTags: async () => {
      const { data } = await fetchn.request<Tag[]>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        params: {
          type: "MANAGED",
        },
        signal,
      });
      return data;
    },

    addManagedTag: async (name: string) => {
      await fetchn.request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        data: {
          name,
          type: "MANAGED",
          filter: TagFilter.ALL,
          bookIds: [],
        },
        signal,
      });
    },

    //add TagEvents
    getEventTags: async (type: string) => {
      const { data } = await fetchn.request<Tag[]>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        params: {
          type,
        },
        signal,
      });
      return data;
    },

    addEventTag: async (type: string, name: string) => {
      await fetchn.request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        data: {
          name,
          type,
          filter: TagFilter.ALL,
          bookIds: [],
        },
        signal,
      });
    },

    //add managed
    getCustomTags: async () => {
      const { data } = await fetchn.request<Tag[]>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        params: {
          type: "CUSTOM",
        },
        signal,
      });
      return data;
    },

    addCustomdTag: async (name: string) => {
      await fetchn.request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        data: {
          name,
          type: "CUSTOM",
          filter: TagFilter.ALL,
          bookIds: [],
        },
        signal,
      });
    },

    modifyTag: async (
      tagId: number,
      title: string,
      filter: string,
      imageUrl: string,
      webImageUrl: string,
      enabled: boolean,
      metaDescription: string,
      description: string
    ) => {
      await fetchn.request({
        method: "PATCH",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/${tagId}`,
        data: {
          name: title,
          filter: filter,
          imageUrl,
          webImageUrl,
          enabled,
          metaDescription,
          description,
        },
        signal,
      });
    },

    getAllTags: async () => {
      const { data } = await fetchn.request<Tag[]>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        params: {
          enabled: true,
        },
        signal,
      });
      return data;
    },

    getTags: async () => {
      const { data } = await fetchn.request<Tag[]>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        params: {
          type: "CUSTOM",
        },
        signal,
      });
      return data;
    },

    getTagWithBooks: async (tagId: number) => {
      const { data } = await fetchn.request<TagWithTagMappings>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/${tagId}`,
        signal,
      });
      return data;
    },
    addTag: async (name: string) => {
      await fetchn.request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/`,
        data: {
          name,
          type: "CUSTOM",
          filter: TagFilter.ALL,
          bookIds: [],
        },
        signal,
      });
    },
    deleteTag: async (tagId: number) => {
      await fetchn.request({
        method: "DELETE",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/tags/${tagId}`,
        signal,
      });
    },
    updateTagMappings: async (tagId: number, bookIds: number[]) => {
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/tags/${tagId}/mappings/reset`,
        data: { bookIds },
        signal,
      });
    },
  };
};

export default TagApi;
