import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  Checkbox,
  Container,
  IconButton,
  TextField,
} from "@mui/material";
import { TableColumn } from "react-data-table-component";
import { Link as RouterLink } from "react-router-dom";
import PlusIcon from "icons/Plus";
import { Tag } from "types/Content";
import PencilAltIcon from "icons/PencilAlt";
import { useMutation, useQueryClient } from "react-query";
import TagApi from "apis/Tag";
import { LoadingButton } from "@mui/lab";
import useEventsTag from "../../../hooks/useEventsTag";
import useSearchParamsState from "../../../hooks/useSearchParams";
import { DataGrid } from "../../../components";
import QueryKey from "../../../constants/QueryKey";

export enum TagEventsID {
  READING_GATE_LEVEL = "READING_GATE_LEVEL",
}

const optionTagEventsIDs: { label: string; value: TagEventsID }[] = [
  { label: "리딩게이트", value: TagEventsID.READING_GATE_LEVEL },
];

const useTagEventsList = () => {
  const type = TagEventsID.READING_GATE_LEVEL;
  const queryClient = useQueryClient();
  const { searchParams, setSearchParams } = useSearchParamsState<{
    type: TagEventsID;
  }>({
    defaultValues: {
      type: TagEventsID.READING_GATE_LEVEL,
    },
    parse: {},
  });

  useEffect(() => {
    setSearchParams({ ...searchParams });
  }, []);

  const { tags, isFetching } = useEventsTag(searchParams.type);

  // @ts-ignore
  const { mutate, isLoading: isAdding } = useMutation(
    (name: string) => TagApi().addEventTag(searchParams.type, name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.eventtags);
      },
    }
  );

  const handleAddTag = () => {
    const name = window.prompt("태그명을 입력해주세요.");

    if (name?.length > 0) {
      mutate(name);
    }
  };

  const actions = [
    <LoadingButton
      key="test"
      color="primary"
      startIcon={<PlusIcon fontSize="small" />}
      sx={{ m: 1 }}
      variant="contained"
      onClick={handleAddTag}
      loading={isAdding}
    >
      태그 추가
    </LoadingButton>,
  ];

  return {
    handleAddTag,
    handleChangeStatus: (type: TagEventsID) =>
      setSearchParams({ ...searchParams, type }),
    searchParams,
    isFetching,
    actions,
    isAdding,
    tags,
  };
};
const TagEventsList: React.FC = () => {
  const {
    searchParams,
    handleAddTag,
    isFetching,
    actions,
    isAdding,
    tags,
    handleChangeStatus,
  } = useTagEventsList();
  const columns: TableColumn<Tag>[] = useMemo(
    () => [
      {
        name: "idx",
        selector: (row) => row.idx,
        width: "10%",
      },
      {
        name: "ID",
        selector: (row) => row.id,
        width: "10%",
      },
      {
        name: "태그명",
        selector: (row) => row.name,
        wrap: true,
        width: "60%",
      },
      {
        name: "활성화여부",
        wrap: true,
        width: "10%",
        cell: (row) => (
          <Checkbox
            checked={row.enabled}
            onClick={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        ),
      },
      {
        name: "수정",
        button: true,
        width: "10%",
        cell: (row) => (
          <IconButton
            component={RouterLink}
            to={`/tag/tagevents/${row.id}`}
            sx={{ mr: 1 }}
          >
            <PencilAltIcon fontSize="small" />
          </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>Events 태그 목록</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ m: 1, maxWidth: "100%", width: 240 }}>
            <TextField
              size="small"
              fullWidth
              label="이벤트 목록"
              name="status"
              onChange={(e) =>
                handleChangeStatus(e.target.value as TagEventsID)
              }
              select
              SelectProps={{ native: true }}
              value={searchParams.type as TagEventsID}
              variant="outlined"
            >
              {optionTagEventsIDs.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>

          <Card>
            <DataGrid
              fixedHeader
              progressPending={isFetching}
              columns={columns}
              data={tags}
              title="Events 태그 목록"
              actions={actions}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default TagEventsList;
