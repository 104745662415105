import {
  AdditionalPaymentType,
  AffiliatedCompanyMemberStatus,
  AppBannerDisplayPosition,
  AvailablePlatformType,
  BookStoreRequestReason,
  BookStoreRequestStatus,
  CashChangeAction,
  CertificationHistoryStatus,
  ChangeWoodoSectionStatus,
  ConsignmentRegistrationApprovalStatus,
  ConsignmentRegistrationStatus,
  ContentRecommendStatus,
  CouponType,
  DepositAction,
  DisplayPlatform,
  ExecutorType,
  ExternalExpectStoreBookType,
  ExternalOrderChannel,
  ExternalStoreType,
  LibraryMemoType,
  LibraryType,
  MvpOrderAction,
  MvpOrderStatus,
  OrderLogAction,
  OrderMemoType,
  PartnerLibraryBannerLanguage,
  PaymentChannel,
  PgProvider,
  PointAction,
  PublishType,
  ReadingNotesType,
  ReceptionType,
  RecommenderRewardType,
  RequestApprovalStatus,
  ReturnDeliveryStatus,
  ReturnType,
  SendDeliveryStatus,
  SendDeliveryType,
  ShippingCompany,
  StockMemoType,
  StockStatus,
  UsedStockStatus,
  WebBannerDisplayPosition,
} from "./Server";

export const TextPublishType: { readonly [key in PublishType]: string } = {
  [PublishType.SINGLE]: "단행본",
  [PublishType.SET]: "전집",
  [PublishType.PERIODICAL]: "간행물",
  [PublishType.BOARD_GAME]: "보드게임",
  [PublishType.WORKBOOK]: "문제집",
};

export const TextLibraryType: { readonly [key in LibraryType]: string } = {
  [LibraryType.BRAND]: "브랜드",
  [LibraryType.DIRECT]: "직영",
  [LibraryType.NORMAL]: "이웃",
  [LibraryType.PUBLISHER]: "출판사",
  [LibraryType.B2B]: "B2B",
  [LibraryType.READERS_CLUB]: "리더스 클럽",
};

export const TextStockStatus: { readonly [key in StockStatus]: string } = {
  [StockStatus.IDLE]: "대여가능",
  [StockStatus.RESERVED]: "주문됨",
  [StockStatus.RETURN_RESERVED]: "반환 주문됨",
  [StockStatus.PACKED]: "패킹됨",
  [StockStatus.LENT]: "대여중",
  [StockStatus.PENDING_RETURN_CONFIRM]: "회수확인중",
  [StockStatus.CLOSED]: "대여안받기",
  [StockStatus.LOST]: "분실",
  [StockStatus.BE_SOLD]: "판매대기",
  [StockStatus.SOLD]: "판매됨",
  [StockStatus.DISCARDED]: "삭제됨",
};

export const TextOrderLogAction: { readonly [key in OrderLogAction]: string } =
  {
    [OrderLogAction.ADD_ITEM]: "주문 도서 추가",
    [OrderLogAction.ADJUST_OMISSION_OVERDUE_DAYS]: "누락일 조정",
    [OrderLogAction.ADJUST_OVERDUE_DAYS]: "연체일 조정",
    [OrderLogAction.ADJUST_TOTAL_OVERDUE_FEE]: "연체료 조정",
    [OrderLogAction.CANCEL]: "주문자 대여 취소 (직거래)",
    [OrderLogAction.CHANGE_DESTINATION]: "주소 변경",
    [OrderLogAction.CHANGE_RENTAL_DAYS]: "대여기간 변경",
    [OrderLogAction.CHANGE_RETURN_DELIVERY_CODE]: "회수 운송장 번호 변경",
    [OrderLogAction.CHANGE_SEND_DELIVERY_CODE]: "발송 운송장 변경",
    [OrderLogAction.DELETE]: "주문 도서 삭제",
    [OrderLogAction.DELIVERY_COMPLETED]: "배송 완료",
    [OrderLogAction.DELIVERY_CONFIRMED]: "배송 완료 확인",
    [OrderLogAction.DELIVERY_STARTED]: "발송",
    [OrderLogAction.GATHERING_COMPLETED]: "발송 준비 완료",
    [OrderLogAction.PACK_ITEMS]: "도서 패킹",
    [OrderLogAction.PAY_OVERDUE_FEE]: "연체료 납부",
    [OrderLogAction.PURCHASE_RENTED]: "도서 구매",
    [OrderLogAction.REJECT]: "관리자 취소 / 공유자 취소 (직거래)",
    [OrderLogAction.REJECT_ITEMS]: "주문 도서 취소",
    [OrderLogAction.REJECT_RETURN_REQUESTED]: "회수 신청 취소",
    [OrderLogAction.RESTORE_PACKED_ITEMS]: "도서 패킹 취소",
    [OrderLogAction.RESTORE_REJECTED_ITEMS]: "주문 도서 취소 복원",
    [OrderLogAction.RESTORE_RETURN_CONFIRMED_ITEMS]: "주문 도서 회수 확인 취소",
    [OrderLogAction.RETURN_COMPLETED]: "회수 완료",
    [OrderLogAction.RETURN_CONFIRM]: "회수 확인",
    [OrderLogAction.RETURN_CONFIRM_ITEMS]: "주문 도서 회수 확인",
    [OrderLogAction.RETURN_REQUESTED]: "회수 신청",
    [OrderLogAction.RETURN_STARTED]: "회수 시작",
    [OrderLogAction.START_GATHERING]: "발송 준비 시작",
    [OrderLogAction.PICK_ITEMS]: "도서 피킹",
    [OrderLogAction.RETURN_UNPACKING]: "회수 작업 시작",
  };

export const TextAdditionalPaymentType: {
  readonly [key in AdditionalPaymentType]: string;
} = {
  [AdditionalPaymentType.EXTEND_RENTAL_DURATION]: "연장",
  [AdditionalPaymentType.PAY_OVERDUE_FEE]: "연체",
  [AdditionalPaymentType.PURCHASE]: "구매",
};

export const TextDepositAction: { readonly [key in DepositAction]: string } = {
  [DepositAction.ADMIN_ACCUMULATION]: "관리자 적립",
  [DepositAction.ADMIN_DEDUCTION]: "관리자 차감",
  [DepositAction.CANCEL_CONSIGNMENT_RENT]: "위탁 공유 취소",
  [DepositAction.CANCEL]: "대여 결제 취소",
  [DepositAction.CONSIGNMENT_RENT]: "위탁 공유 적립",
  [DepositAction.DIRECT_RENT]: "직거래 공유 적립",
  [DepositAction.EXTEND_CONSIGNMENT_RENT]: "위탁 공유 적립 (연장)",
  [DepositAction.EXTEND_RENTAL_DURATION]: "연장 결제",
  [DepositAction.OVERDUE_CONSIGNMENT_RENT]: "위탁 연체료",
  [DepositAction.OVERDUE_PAID]: "연체료 결제",
  [DepositAction.OVERDUE]: "공유 적립",
  [DepositAction.PAID]: "대여 결제",
  [DepositAction.PARTIAL_CANCEL]: "부분 취소 환불",
  [DepositAction.POINT_PURCHASED]: "포인트 충전",
  [DepositAction.PURCHASED]: "구매 결제",
  [DepositAction.SOLD]: "위탁도서 판매",
  [DepositAction.WITHDRAWAL]: "인출",
  [DepositAction.SUBSCRIBED]: "구독 결제",
  [DepositAction.BOOK_CONSIGNMENT_REGISTER]: "위탁 배송비 결제",
  [DepositAction.BOOK_CONSIGNMENT_RETURN]: "위탁 반송 배송비 결제",
  [DepositAction.SHARE_LINK]: "공유 링크 적립",
  [DepositAction.RECOMMENDER_RENT]: "친구 대여 적립",
};

export const TextPointAction: { readonly [key in PointAction]: string } = {
  [PointAction.ACCUMULATE_BY_ADMIN]: "관리자 적립",
  [PointAction.ADD_CONSIGNMENT_ITEM]: "위탁도서 등록",
  [PointAction.ADD_ITEM]: "도서 등록",
  [PointAction.ADMIN_DEDUCTION]: "관리자 차감",
  [PointAction.CHARGE]: "포인트 충전",
  [PointAction.CONTENT_REVIEW]: "서평 작성",
  [PointAction.EARLY_RETURN]: "조기 반납 혜택",
  [PointAction.EXPIRED]: "유효기간 만료",
  [PointAction.EXTEND_RENTAL_DURATION]: "연장 결제",
  [PointAction.EXTEND_RENTAL_CANCELED]: "연장 결제 취소",
  [PointAction.ORDER_CANCELED]: "대여 결제 취소",
  [PointAction.ORDERED]: "대여 결제",
  [PointAction.PURCHASED]: "구매 결제",
  [PointAction.RECOMMEND]: "이웃도서관 추천",
  [PointAction.RECOMMENDED_BY]: "이웃도서관 추천",
  [PointAction.REORDER]: "재주문",
  [PointAction.RETURN_COMPLETED]: "직거래 완료",
  [PointAction.RETURN_REQUESTED]: "24시간 포인트",
  [PointAction.WELCOME]: "웰컴 포인트",
};

export const TextPgProvider: { readonly [key in PgProvider]: string } = {
  [PgProvider.KAKAOPAY]: "카카오페이",
  [PgProvider.NAVERPAY]: "네이버페이",
  [PgProvider.NICE]: "신용카드",
};

export const TextPaymentChannel: { readonly [key in PaymentChannel]: string } =
  {
    [PaymentChannel.API]: "카드간편결제",
    [PaymentChannel.MOBILE]: "일반결제",
  };

export const TextConsignmentRegistrationStatus: {
  readonly [key in ConsignmentRegistrationStatus]: string;
} = {
  [ConsignmentRegistrationStatus.CANCELED]: "신청 취소",
  [ConsignmentRegistrationStatus.COMPLETED]: "등록 완료",
  [ConsignmentRegistrationStatus.DELIVERY_RECEPTION]:
    "신청 완료 - 택배접수완료",
  [ConsignmentRegistrationStatus.DELIVERY_COMPLETED]:
    "신청 완료 - 택배배송완료",
  [ConsignmentRegistrationStatus.NONE]: "신청 완료",
  [ConsignmentRegistrationStatus.REGISTERING]: "등록 중",
};

export const TextConsignmentRegistrationApprovalStatus: {
  readonly [key in ConsignmentRegistrationApprovalStatus]: string;
} = {
  [ConsignmentRegistrationApprovalStatus.NONE]: "미처리",
  [ConsignmentRegistrationApprovalStatus.APPROVED]: "승인",
  [ConsignmentRegistrationApprovalStatus.REJECTED]: "거부",
};

export const TextReceptionType: { readonly [key in ReceptionType]: string } = {
  [ReceptionType.LIBRARY_DELIVERY]: "고객 접수 택배 위탁",
  [ReceptionType.RETURN_DELIVERY_REQUESTED]: "대여 도서 반납 시 위탁",
  [ReceptionType.VISIT]: "방문 위탁",
  [ReceptionType.WOODO_DELIVERY]: "우도 접수 택배 위탁",
};

export const getTextDeliveryStatus = (
  sendDeliveryStatus: SendDeliveryStatus,
  returnDeliveryStatus: ReturnDeliveryStatus
) => {
  if (returnDeliveryStatus === ReturnDeliveryStatus.REQUESTED) {
    return "회수신청";
  }
  if (returnDeliveryStatus === ReturnDeliveryStatus.STARTED) {
    return "회수중";
  }
  if (returnDeliveryStatus === ReturnDeliveryStatus.COMPLETED) {
    return "회수완료";
  }
  if (returnDeliveryStatus === ReturnDeliveryStatus.UNPACKING) {
    return "회수처리중";
  }
  if (returnDeliveryStatus === ReturnDeliveryStatus.CONFIRMED) {
    return "회수확인";
  }
  if (sendDeliveryStatus === SendDeliveryStatus.NONE) {
    return "배송준비중";
  }
  if (sendDeliveryStatus === SendDeliveryStatus.GATHERING) {
    return "배송준비중";
  }
  if (sendDeliveryStatus === SendDeliveryStatus.GATHERED) {
    return "배송준비완료";
  }
  if (sendDeliveryStatus === SendDeliveryStatus.STARTED) {
    return "배송중";
  }
  if (sendDeliveryStatus === SendDeliveryStatus.COMPLETED) {
    return "배송완료";
  }
  if (sendDeliveryStatus === SendDeliveryStatus.CONFIRMED) {
    return "배송완료";
  }
};

export const TextDisplayPlatform: {
  readonly [key in DisplayPlatform]: string;
} = {
  [DisplayPlatform.ALL]: "모두",
  [DisplayPlatform.APP]: "APP",
  [DisplayPlatform.WEB]: "WEB",
};

export const TextAvailablePlatformType: {
  readonly [key in AvailablePlatformType]: string;
} = {
  [AvailablePlatformType.ALL]: "모두",
  [AvailablePlatformType.APP]: "APP",
  [AvailablePlatformType.WEB]: "WEB",
};

export const TextCouponType: { readonly [key in CouponType]: string } = {
  [CouponType.EVERYONE]: "전체 사용자",
  [CouponType.LIMITED_LIBRARY]: "지정 사용자",
};

export const TextBookStoreRequestReason: {
  readonly [key in BookStoreRequestReason]: string;
} = {
  [BookStoreRequestReason.NEW]: "신간",
  [BookStoreRequestReason.BEST_STEADY_SELLER]: "베스트셀러/스테디셀러",
  [BookStoreRequestReason.MUST_READ]: "필독서",
  [BookStoreRequestReason.RECOMMEND]: "추천/권장 도서",
  [BookStoreRequestReason.TEXTBOOK]: "교재",
  [BookStoreRequestReason.ETC]: "기타",
};

export const TextContentRecommendStatus: {
  readonly [key in ContentRecommendStatus]: string;
} = {
  [ContentRecommendStatus.PENDING]: "대기중",
  [ContentRecommendStatus.APPROVED]: "승인",
  [ContentRecommendStatus.REJECTED]: "거부",
};

export const TextBookStoreRequestStatus: {
  readonly [key in BookStoreRequestStatus]: string;
} = {
  [BookStoreRequestStatus.REQUESTED]: "입고요청",
  [BookStoreRequestStatus.ORDERED]: "주문완료",
  [BookStoreRequestStatus.STORED]: "입고완료",
};

export const TextStockMemoType: { readonly [key in StockMemoType]: string } = {
  [StockMemoType.DAMAGE]: "훼손",
  [StockMemoType.ETC]: "기타",
  [StockMemoType.SUPPLEMENT]: "부가물",
};

export const TextOrderMemoType: { readonly [key in OrderMemoType]: string } = {
  [OrderMemoType.DAMAGE]: "훼손",
  [OrderMemoType.ETC]: "기타",
  [OrderMemoType.SUPPLEMENT]: "부가물",
};

export const TextLibraryMemoType: {
  readonly [key in LibraryMemoType]: string;
} = {
  [LibraryMemoType.ADDITIONAL_BOOK]: "추가 도서",
  [LibraryMemoType.INSPECTION]: "검수",
  [LibraryMemoType.ETC]: "기타",
};

export const TextChangeWoodoSectionStatus: {
  readonly [key in ChangeWoodoSectionStatus]: string;
} = {
  [ChangeWoodoSectionStatus.NOT_FOUND]: "미등록 재고",
  [ChangeWoodoSectionStatus.CHANGED]: "변경됨",
  [ChangeWoodoSectionStatus.NOT_CHANGED]: "서가번호 일치",
  [ChangeWoodoSectionStatus.ERROR]: "에러",
};

export const TextRecommenderRewardType: {
  readonly [key in RecommenderRewardType]: string;
} = {
  [RecommenderRewardType.DEPOSIT]: "예치금",
  [RecommenderRewardType.POINT]: "포인트",
};

export const TextCertificationHistoryStatus: {
  readonly [key in CertificationHistoryStatus]: string;
} = {
  [CertificationHistoryStatus.DONE]: "인증 완료",
  [CertificationHistoryStatus.PROCEEDING]: "발급 완료",
  [CertificationHistoryStatus.PROGRESS]: "사용 안 함",
};

export const TextAppBannerDisplayPosition: {
  readonly [key in AppBannerDisplayPosition]: string;
} = {
  [AppBannerDisplayPosition.MAIN_TOP]: "메인 상단",
  [AppBannerDisplayPosition.SEARCH_TOP]: "검색 메인",
  [AppBannerDisplayPosition.MAIN_WOODO_TOP]: "메인 우도샵",
  [AppBannerDisplayPosition.MAIN_BOTTOM_SHEET]: "메인 바텀 시트",
  [AppBannerDisplayPosition.CLASS_CHILD_TOP]: "유아홈 상단",
  [AppBannerDisplayPosition.CLASS_ELEMENTARY_TOP]: "초등 상단",
  [AppBannerDisplayPosition.CLASS_TEENAGER_TOP]: "청소년 상단",
  [AppBannerDisplayPosition.CLASS_ADULT_TOP]: "성인 상단",
  [AppBannerDisplayPosition.CLASS_ENGLISH_TOP]: "영어 상단",
};

export const TextWebBannerDisplayPosition: {
  readonly [key in WebBannerDisplayPosition]: string;
} = {
  [WebBannerDisplayPosition.MAIN_TOP]: "메인 상단",
  [WebBannerDisplayPosition.MAIN_TOP_SIDE]: "메인 상단 사이드",
  [WebBannerDisplayPosition.MAIN_MIDDLE_SESSION]: "메인 중단 세션",
  [WebBannerDisplayPosition.MAIN_BOTTOM_SESSION]: "메인 하단 세션",
};

export const TextRequestApprovalStatus: {
  readonly [key in RequestApprovalStatus]: string;
} = {
  [RequestApprovalStatus.PENDING]: "대기",
  [RequestApprovalStatus.APPROVED]: "승인",
  [RequestApprovalStatus.REJECTED]: "반려",
  [RequestApprovalStatus.COMPLETED]: "완료",
};

export const TextSendDeliveryType: {
  readonly [key in SendDeliveryType]: string;
} = {
  [SendDeliveryType.ORDER]: "주문",
  [SendDeliveryType.RETURN]: "반환",
  [SendDeliveryType.NONMEMBER_ORDER]: "비회원 주문",
  [SendDeliveryType.EXTERNAL_ORDER]: "외부 구매 주문",
  [SendDeliveryType.STORAGE_RETURN]: "보관료 발생 반환",
};

export const TextCashChangeAction: {
  readonly [key in CashChangeAction]: string;
} = {
  [CashChangeAction.ADMIN_ACCUMULATION]: "관리자 적립",
  [CashChangeAction.CANCEL]: "전체 대여 취소 (캐시 결제분)",
  [CashChangeAction.PARTIAL_CANCEL]: "부분 대여 취소",
  [CashChangeAction.REGULAR_PAYMENT]: "정기 지급",
  [CashChangeAction.ADMIN_DEDUCTION]: "관리자 차감",
  [CashChangeAction.PAID]: "결제 차감",
  [CashChangeAction.POINT_PURCHASED]: "포인트 구매 차감",
  [CashChangeAction.PURCHASED]: "구매 결제 차감",
  [CashChangeAction.EXTEND_RENTAL_DURATION]: "대여일 연장 결제 차감",
  [CashChangeAction.BOOK_CONSIGNMENT_REGISTER]: "위탁 배송비 결제",
  [CashChangeAction.BOOK_CONSIGNMENT_RETURN]: "위탁 반송 배송비 결제",
};

export const TextPartnerLibraryBannerLanguage: {
  readonly [key in PartnerLibraryBannerLanguage]: string;
} = {
  [PartnerLibraryBannerLanguage.KOREAN]: "한글",
  [PartnerLibraryBannerLanguage.ENGLISH]: "영어",
};

export const TextAffiliatedCompanyMemberStatus: {
  readonly [key in AffiliatedCompanyMemberStatus]: string;
} = {
  [AffiliatedCompanyMemberStatus.NONE]: "정상",
  [AffiliatedCompanyMemberStatus.OMITTED]: "누락",
};

export const TextExternalOrderChannel: {
  readonly [key in ExternalOrderChannel]: string;
} = {
  [ExternalOrderChannel.ALADIN]: "알라딘",
  [ExternalOrderChannel.YES_24]: "YES24",
};

export const TextReadingNotesType: {
  readonly [key in ReadingNotesType]: string;
} = {
  [ReadingNotesType.ONE_LINE_SUMMARY]: "한 줄 요약",
  [ReadingNotesType.SENTENCES_BOOK]: "책 속 문장",
  [ReadingNotesType.READING_BOOK]: "독서록",
};

export const TextMvpOrderStatus: { readonly [key in MvpOrderStatus]: string } =
  {
    [MvpOrderStatus.NONE]: "주문 완료",
    [MvpOrderStatus.GATHERING]: "배송 준비중",
    [MvpOrderStatus.SEND_STARTED]: "배송중",
    [MvpOrderStatus.RENT]: "체험중",
    [MvpOrderStatus.RETURN_REQUESTED]: "회수 요청",
    [MvpOrderStatus.RETURN_STARTED]: "회수중",
    [MvpOrderStatus.INSPECTION_COMPLETED]: "회수 완료",
    [MvpOrderStatus.REJECTED]: "회수 반려",
    [MvpOrderStatus.CANCELLED]: "주문 취소",
    [MvpOrderStatus.COMPLETED]: "구매 확정",
  };

export const TextShippingCompany: {
  readonly [key in ShippingCompany]: string;
} = {
  [ShippingCompany.SC_01]: "CJ대한통운",
  [ShippingCompany.SC_02]: "우체국택배",
  [ShippingCompany.SC_03]: "한진택배",
  [ShippingCompany.SC_04]: "롯데택배",
  [ShippingCompany.SC_05]: "로젠택배",
};

export const TextMvpOrderAction: { readonly [key in MvpOrderAction]: string } =
  {
    [MvpOrderAction.ORDER_CREATED]: "주문 생성",
    [MvpOrderAction.START_GATHERING]: "배송 준비중",
    [MvpOrderAction.PACK_ITEMS]: "주문 아이템 패킹",
    [MvpOrderAction.GATHERING_COMPLETED]: "집하 완료",
    [MvpOrderAction.REJECT_DELIVERY_STARTED]: "배송시작 취소",
    [MvpOrderAction.DELIVERY_STARTED]: "배송시작",
    [MvpOrderAction.DELIVERY_COMPLETED]: "배송완료",
    [MvpOrderAction.DELIVERY_CONFIRMED]: "배송확인",
    [MvpOrderAction.RETURN_REQUESTED]: "회수 요청",
    [MvpOrderAction.RETURN_STARTED]: "회수 시작",
    [MvpOrderAction.RETURN_COMPLETED]: "회수 완료",
    [MvpOrderAction.RETURN_UNPACKING]: "회수 작업 시작",
    [MvpOrderAction.RETURN_CONFIRM]: "검수 완료",
    [MvpOrderAction.CANCEL]: "주문 취소",
    [MvpOrderAction.REJECT]: "주문 취소(어드민)",
    [MvpOrderAction.CHANGE_DESTINATION]: "배송지 변경",
    [MvpOrderAction.CHANGE_EXTERNAL_INFO]: "외부 주문 정보 수정",
    [MvpOrderAction.CHANGE_SEND_DELIVERY_CODE]: "배송 운송장 번호 수정",
    [MvpOrderAction.CHANGE_RETURN_DELIVERY_CODE]: "회수 운송장 번호 수정",
    [MvpOrderAction.CHANGE_REJECT_DELIVERY_CODE]: "반려 운송장 번호 수정",
    [MvpOrderAction.PAYMENT_FAILED_AND_CANCEL]: "결제 실패 주문 취소",
    [MvpOrderAction.COMPLETE_ORDER]: "구매확정",
  };

export const TextExecutorType: { readonly [key in ExecutorType]: string } = {
  [ExecutorType.SYSTEM]: "시스템",
  [ExecutorType.ADMIN]: "관리자",
  [ExecutorType.LIBRARY]: "도서관",
  [ExecutorType.NONMEMBER]: "비회원",
  [ExecutorType.PAYMENT_LIBRARY]: "결제 도서관",
};

export const TextUsedStockStatus: {
  readonly [key in UsedStockStatus]: string;
} = {
  [UsedStockStatus.IDLE]: "대기중",
  [UsedStockStatus.RESERVED]: "예약중",
  [UsedStockStatus.PACKED]: "패킹중",
  [UsedStockStatus.LENT]: "체험중",
  [UsedStockStatus.SOLD]: "판매완료",
  [UsedStockStatus.DISCARDED]: "폐기됨",
};

export const TextExternalStoreType: {
  readonly [key in ExternalStoreType]: string;
} = {
  [ExternalStoreType.CS]: "CS",
  [ExternalStoreType.DIRECT]: "직영",
  [ExternalStoreType.DELIVERY]: "배송",
  [ExternalStoreType.REGISTER_CONSIGNMENT]: "바로위탁",
  [ExternalStoreType.RENT]: "대여",
};

export const TextExternalExpectStoreBookType: {
  readonly [key in ExternalExpectStoreBookType]: string;
} = {
  [ExternalExpectStoreBookType.CS]: "CS",
  [ExternalExpectStoreBookType.DIRECT]: "직영",
};

export const TextReturnType: { readonly [key in ReturnType]: string } = {
  [ReturnType.RETURN]: "반환",
  [ReturnType.STORAGE_RETURN]: "보관료 반환",
};
