import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";

import useAuth from "hooks/useAuth";
import { useGoogleLogin } from "react-google-login";

const Login: React.FC = () => {
  const { signIn: login } = useAuth();

  const { signIn, loaded } = useGoogleLogin({
    onSuccess: (response: any) => login(response?.tokenId),
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onFailure: (err) => console.log(err, "err !!"),
  });

  return (
    <>
      <Box />
      <Helmet>
        <title>로그인</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Card sx={{ mt: 8 }}>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", p: 4 }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    우리집은 도서관
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {`우리집은 도서관 관리자 (${process.env.REACT_APP_MODE})`}
                  </Typography>
                </div>
              </Box>
              <Box sx={{ flexGrow: 1, mt: 3 }}>
                <Box sx={{ mt: 2 }}>
                  <Button
                    color="primary"
                    disabled={!loaded}
                    fullWidth
                    type="submit"
                    variant="contained"
                    onClick={signIn}
                  >
                    구글로 로그인 하기
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
