import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@mui/material";
import toast from "react-hot-toast";

import SplashScreen from "pages/SplashScreen";
import useScrollReset from "hooks/useScrollReset";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import useAuth from "hooks/useAuth";

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ko";

dayjs.locale("ko");
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error: any) => {
        if (error?.status === 400) {
          toast.error(error.data?.message);
        } else if (error?.status === 500) {
          toast.error("서버 에러가 발생했습니다.");
        }
        return error;
      },
    },
    mutations: {
      retry: false,
      onError: (error: any) => {
        if (error?.status === 400) {
          toast.error(error.data?.message);
        } else if (error?.status === 500) {
          toast.error("서버 에러가 발생했습니다.");
        }
        return error;
      },
    },
  },
});

const App: FC = () => {
  const content = useRoutes(routes);
  const { isInitialized } = useAuth();

  useScrollReset();

  const theme = createCustomTheme({
    responsiveFontSizes: true,
    roundedCorners: false,
    theme: "LIGHT",
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        {isInitialized ? content : <SplashScreen />}
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
