import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Card, Checkbox, Container, IconButton } from "@mui/material";
import { TableColumn } from "react-data-table-component";
import { Link as RouterLink } from "react-router-dom";

import PlusIcon from "icons/Plus";
import { DataGrid } from "components";
import { Tag } from "types/Content";
import dayjs from "dayjs";
import PencilAltIcon from "icons/PencilAlt";
import { useMutation, useQueryClient } from "react-query";
import QueryKey from "constants/QueryKey";
import { LoadingButton } from "@mui/lab";
import useHomeTag from "../../../hooks/useHomeTag";
import HomeTagApi from "../../../apis/HomeTag";

const HomeTagList: React.FC = () => {
  const queryClient = useQueryClient();
  const { tags, isFetching } = useHomeTag();
  const { mutate, isLoading: isAdding } = useMutation(
    (name: string) => HomeTagApi().addTag(name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.hometags);
      },
    }
  );

  const handleAddTag = () => {
    const name = window.prompt("태그명을 입력해주세요.");

    if (name?.length > 0) {
      mutate(name);
    }
  };

  const actions = [
    <LoadingButton
      key="test"
      color="primary"
      startIcon={<PlusIcon fontSize="small" />}
      sx={{ m: 1 }}
      variant="contained"
      onClick={handleAddTag}
      loading={isAdding}
    >
      태그 추가
    </LoadingButton>,
  ];

  const columns: TableColumn<Tag>[] = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        width: "100px",
      },
      {
        name: "태그명",
        selector: (row) => row.name,
        wrap: true,
      },
      {
        name: "사용여부",
        //selector: row => (row.enabled,)
        wrap: true,
        cell: (row) => (
          <Checkbox
            checked={row.enabled}
            onClick={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        ),
      },
      {
        name: "생성일",
        selector: (row) => row.createdAt,
        format: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm"),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <IconButton
            component={RouterLink}
            to={`/home/hometag/${row.id}`}
            sx={{ mr: 1 }}
          >
            <PencilAltIcon fontSize="small" />
          </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>홈 태그 목록</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Card>
            <DataGrid
              fixedHeader
              progressPending={isFetching}
              columns={columns}
              data={tags}
              title="홈 태그 목록"
              actions={actions}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default HomeTagList;
