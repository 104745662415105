import { Language, PublishType } from "constants/Server";
import { ContentPeriodicalPromotionEntity } from "types/Content";
import fetchn from "utils/Fetchn";
import { BestLibraryEntity, LastOrderEntity } from "../types/DashBoard";

interface DashBoardBodyRequest {
  classCategoryIds: string[];
  discountRate: number;
  enabled: boolean;
  languages: Language[];
  prizes: string[];
  promotionEventBookExisted?: boolean;
  publishTypes: PublishType[];
  quizExisted: boolean;
  sequence?: number;
  tagIds: number[];
  title: string;
  validFrom: string;
  validTo: string;
}

const DashBoardApi = (signal?) => {
  return {
    getInfo: async () => {
      const { data } = await fetchn.request<LastOrderEntity[]>({
        url:
          process.env.REACT_APP_SERVER_URI + `/manager/v0/statistics/dashboard`,
        signal,
      });
      return data;
    },

    getLastOrder: async () => {
      const { data } = await fetchn.request<LastOrderEntity[]>({
        url: `/data/lastorder.json`,
        signal,
      });
      return data;
    },

    getBestLibrary: async () => {
      const { data } = await fetchn.request<BestLibraryEntity[]>({
        url: `/data/bestlibrary.json`,
        signal,
      });
      return data;
    },
  };
};

export default DashBoardApi;
