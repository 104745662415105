import React from "react";
import type { FC } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { colors, Fade } from "@mui/material";

interface ChartProps {
  className?: string;
  data: any;
  labels: string[];
}

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
});

const OrderChart: FC<ChartProps> = ({
  data: dataProp,
  labels,
  className,
  ...rest
}) => {
  const classes = useStyles();
  // const theme: Theme = useTheme();

  const data = {
    datasets: [
      {
        label: "This year",
        backgroundColor: "#5850EC",
        data: dataProp.thisYear,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
      {
        label: "Last year",
        backgroundColor: "#5850EC",
        data: dataProp.lastYear,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
    labels,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            fontColor: colors.blueGrey[600],
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: colors.blueGrey[100],
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: colors.blueGrey[100],
          },
          ticks: {
            padding: 20,
            fontColor: colors.blueGrey[600],
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            callback: (value: number) => (value > 0 ? `${value}권` : value),
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: colors.blueGrey[100],
      backgroundColor: "#f4f6f8",
      titleFontColor: colors.blueGrey[900],
      bodyFontColor: colors.blueGrey[600],
      footerFontColor: colors.blueGrey[600],
      callbacks: {
        title: () => {},
        label: (tooltipItem: any) => {
          let label = `주문: ${tooltipItem.yLabel}`;

          if (tooltipItem.yLabel > 0) {
            label += "권";
          }

          return label;
        },
      },
    },
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Bar data={data} options={options} />
    </div>
  );
};

OrderChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
};

export default OrderChart;
