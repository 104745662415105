import type { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { experimentalStyled } from "@mui/material/styles";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return <MainLayoutRoot>{children || <Outlet />}</MainLayoutRoot>;
};

export default MainLayout;
