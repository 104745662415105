import { memo } from "react";
import DataTable, { TableProps } from "react-data-table-component";

const DataGrid = memo(({ fixedHeader, pagination, data, ...props }: any) => {
  return (
    <DataTable
      contextMessage={{
        singular: "개",
        plural: "개",
        message: "선택",
      }}
      data={data}
      persistTableHead
      fixedHeader={fixedHeader}
      pagination={pagination}
      paginationServer
      paginationRowsPerPageOptions={[30, 50, 100, 500, 1000]}
      sortServer={pagination}
      selectableRowsHighlight
      customStyles={
        fixedHeader && {
          table: {
            style: {
              height: `calc(100vh - ${pagination ? 300 : 245}px)`,
            },
          },
        }
      }
      {...props}
    />
  );
});

export default DataGrid;
