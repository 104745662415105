import { Helmet } from "react-helmet-async";
import { Avatar, Box, Card, Container, Grid, Typography } from "@mui/material";
import numeral from "numeral";

import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { blue, green } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import Payment from "./dashboard/Payment";
import Order from "./dashboard/Order";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useQuery } from "react-query";
import QueryKey from "../constants/QueryKey";
import DashBoardApi from "../apis/DashBoard";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import CelebrationIcon from "@mui/icons-material/Celebration";
import User from "./dashboard/User";
import WebIcon from "@mui/icons-material/Web";

const cardstyle = makeStyles({
  root: {
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  box: {
    paddingLeft: 10,
  },

  usericon: {
    backgroundColor: "red",
    height: 48,
    width: 48,
  },
});

const Overview: React.FC = () => {
  const cardstyleconst = cardstyle();

  const { data: dashboardinfo } = useQuery(
    [QueryKey.dashboardInfo],
    DashBoardApi().getInfo
  );

  if (dashboardinfo == null) return <></>;

  const user_android_kakao =
    dashboardinfo["totalLibraryCountInfo"]["ANDROID"]["KAKAO"];
  const user_android_google =
    dashboardinfo["totalLibraryCountInfo"]["ANDROID"]["GOOGLE"];
  const user_android_apple = 0;

  const user_ios_kakao = dashboardinfo["totalLibraryCountInfo"]["IOS"]["KAKAO"];
  const user_ios_google =
    dashboardinfo["totalLibraryCountInfo"]["IOS"]["GOOGLE"];
  const user_ios_apple = dashboardinfo["totalLibraryCountInfo"]["IOS"]["APPLE"];

  const user_web_kakao = dashboardinfo["totalLibraryCountInfo"]["WEB"]["KAKAO"];
  const user_web_google =
    dashboardinfo["totalLibraryCountInfo"]["WEB"]["GOOGLE"];
  const user_web_apple = dashboardinfo["totalLibraryCountInfo"]["WEB"]["APPLE"];

  return (
    <>
      <Helmet>
        <title>Index</title>
      </Helmet>

      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid container sx={{ py: 5, pl: 3 }} spacing={3}>
            <Grid item lg={3} sm={3} xs={12}>
              <Card className={clsx(cardstyleconst.root)}>
                <Box flexGrow={1} className={clsx(cardstyleconst.box)}>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    전체 가입자
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {numeral(
                      dashboardinfo["iosLibraryCount"] +
                        dashboardinfo["androidLibraryCount"] +
                        dashboardinfo["webLibraryCount"]
                    ).format("0,0[.]00")}{" "}
                    명
                  </Typography>

                  <Typography color="textPrimary" fontSize={12}>
                    kakao :{" "}
                    {numeral(user_ios_kakao + user_android_kakao).format(
                      "0,0[.]00"
                    )}{" "}
                    | google :{" "}
                    {numeral(user_ios_google + user_android_google).format(
                      "0,0[.]00"
                    )}{" "}
                    | apple : {numeral(user_ios_apple).format("0,0[.]00")}
                  </Typography>
                </Box>

                <Avatar sx={{ bgcolor: green[500], marginRight: 1 }}>
                  <PersonIcon />
                </Avatar>
              </Card>
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
              <Card className={clsx(cardstyleconst.root)}>
                <Box flexGrow={1} className={clsx(cardstyleconst.box)}>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    Android
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {numeral(dashboardinfo["androidLibraryCount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    명
                  </Typography>

                  <Typography color="textPrimary" fontSize={12}>
                    kakao : {numeral(user_android_kakao).format("0,0[.]00")} |
                    google : {numeral(user_android_google).format("0,0[.]00")}
                  </Typography>
                </Box>

                <Avatar
                  sx={{ bgcolor: "#A4C639", color: "white", marginRight: 1 }}
                >
                  <AndroidIcon />
                </Avatar>
              </Card>
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
              <Card className={clsx(cardstyleconst.root)}>
                <Box flexGrow={1} className={clsx(cardstyleconst.box)}>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    IOS
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {numeral(dashboardinfo["iosLibraryCount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    명
                  </Typography>

                  <Typography color="textPrimary" fontSize={12}>
                    kakao : {numeral(user_ios_kakao).format("0,0[.]00")} |
                    google : {numeral(user_ios_google).format("0,0[.]00")} |
                    apple : {numeral(user_ios_apple).format("0,0[.]00")}
                  </Typography>
                </Box>

                <Avatar sx={{ bgcolor: "black", marginRight: 1 }}>
                  <AppleIcon />
                </Avatar>
              </Card>
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
              <Card className={clsx(cardstyleconst.root)}>
                <Box flexGrow={1} className={clsx(cardstyleconst.box)}>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    WEB
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {numeral(dashboardinfo["webLibraryCount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    명
                  </Typography>

                  <Typography color="textPrimary" fontSize={12}>
                    kakao : {numeral(user_web_kakao).format("0,0[.]00")} |
                    google : {numeral(user_web_google).format("0,0[.]00")} |
                    apple : {numeral(user_web_apple).format("0,0[.]00")}
                  </Typography>
                </Box>

                <Avatar sx={{ bgcolor: "black", marginRight: 1 }}>
                  <WebIcon />
                </Avatar>
              </Card>
            </Grid>
          </Grid>

          <Grid container sx={{ py: 0, pl: 3 }} spacing={3}>
            <Grid item lg={12} sm={12} xs={12}>
              <User dashboardinfo={dashboardinfo} />
            </Grid>
          </Grid>

          <Grid container sx={{ py: 3, pl: 3 }} spacing={3}>
            <Grid item lg={4} sm={4} xs={12}>
              <Card className={clsx(cardstyleconst.root)}>
                <Box flexGrow={1} className={clsx(cardstyleconst.box)}>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    이번달 매출
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {numeral(dashboardinfo["thisMonthSalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원
                  </Typography>
                  <Typography color="textPrimary" fontSize={12}>
                    web :{" "}
                    {numeral(dashboardinfo["thisMonthWebSalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원 | mobile :{" "}
                    {numeral(dashboardinfo["thisMonthMobSalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원
                  </Typography>
                </Box>

                <Avatar sx={{ bgcolor: blue[500], marginRight: 1 }}>
                  <CelebrationIcon />
                </Avatar>
              </Card>
            </Grid>

            <Grid item lg={4} sm={4} xs={12}>
              <Card className={clsx(cardstyleconst.root)}>
                <Box flexGrow={1} className={clsx(cardstyleconst.box)}>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    어제 매출
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {numeral(dashboardinfo["lastDaySalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원
                  </Typography>
                  <Typography color="textPrimary" fontSize={12}>
                    web :{" "}
                    {numeral(dashboardinfo["lastDayWebSalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원 | mobile :{" "}
                    {numeral(dashboardinfo["lastDayMobSalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원
                  </Typography>
                </Box>

                <Avatar sx={{ bgcolor: blue[500], marginRight: 1 }}>
                  <AttachMoneyIcon />
                </Avatar>
              </Card>
            </Grid>

            <Grid item lg={4} sm={4} xs={12}>
              <Card className={clsx(cardstyleconst.root)}>
                <Box flexGrow={1} className={clsx(cardstyleconst.box)}>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    금일 매출
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {numeral(dashboardinfo["todaySalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원
                  </Typography>
                  <Typography color="textPrimary" fontSize={12}>
                    web :{" "}
                    {numeral(dashboardinfo["todayWebSalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원 | mobile :{" "}
                    {numeral(dashboardinfo["todayMobSalesAmount"]).format(
                      "0,0[.]00"
                    )}{" "}
                    원
                  </Typography>
                </Box>

                <Avatar sx={{ bgcolor: blue[500], marginRight: 1 }}>
                  <AttachMoneyIcon />
                </Avatar>
              </Card>
            </Grid>
          </Grid>

          <Grid container sx={{ py: 3, pl: 3 }} spacing={3}>
            <Grid item lg={6} sm={12} xs={12}>
              <Payment dashboardinfo={dashboardinfo} />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <Order dashboardinfo={dashboardinfo} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Overview;
