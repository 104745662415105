import type { FC, ReactNode } from "react";
import { lazy, Suspense, useState } from "react";
import type { RouteObject } from "react-router";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "hooks/useAuth";

import DashboardLayout from "layouts/DashboardLayout";
import MainLayout from "layouts/MainLayout";
import LoadingScreen from "pages/LoadingScreen";
import Overview from "pages/Overview";
import Login from "pages/Login";
import CategoryChangeRequestList from "pages/books/CategoryChangeRequestList";
import HomeTagList from "./pages/home/hometag/HomeTagList";
import HomeTagEdit from "./pages/home/hometag/HomeTagEdit";
import TagManagedList from "./pages/tag/managed/TagManagedList";
import TagManagedEdit from "./pages/tag/managed/TagManagedEdit";
import TagCustomList from "./pages/tag/custom/TagCustomList";
import TagCustomEdit from "./pages/tag/custom/TagCustomEdit";
import TagEventsList from "./pages/tag/events/TagEventsList";
import TagEventsEdit from "./pages/tag/events/TagEventsEdit";
import TagSeriesList from "./pages/tag/series/TagSeriesList";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const LibraryList = Loadable(
  lazy(() => import("./pages/libraries/LibraryList"))
);
const LibraryDetail = Loadable(
  lazy(() => import("./pages/libraries/LibraryDetail"))
);
const LibraryEdit = Loadable(
  lazy(() => import("./pages/libraries/LibraryEdit"))
);
const CartRecentRegisterLibraryList = Loadable(
  lazy(() => import("./pages/libraries/CartRecentRegisterLibraryList"))
);
const BookList = Loadable(lazy(() => import("./pages/books/BookList")));
const BookExcelSearchList = Loadable(
  lazy(() => import("./pages/books/BookExcelSearchList"))
);
const ConsignmentAvailableBookList = Loadable(
  lazy(() => import("./pages/books/ConsignmentAvailableBookList"))
);
const BookEdit = Loadable(lazy(() => import("./pages/books/BookEdit")));
const TagList = Loadable(lazy(() => import("./pages/books/TagList")));
const TagEdit = Loadable(lazy(() => import("./pages/books/TagEdit")));
const StockList = Loadable(lazy(() => import("./pages/stocks/StockList")));
const StockEdit = Loadable(lazy(() => import("./pages/stocks/StockEdit")));
const StockDetail = Loadable(lazy(() => import("./pages/stocks/StockDetail")));
const OrderList = Loadable(lazy(() => import("./pages/orders/OrderList")));
const OrderDetail = Loadable(lazy(() => import("./pages/orders/OrderDetail")));
const OrderEdit = Loadable(lazy(() => import("./pages/orders/OrderEdit")));
const DeliveriesSend = Loadable(
  lazy(() => import("./pages/deliveries/DeliveriesSend"))
);
const DeliveriesReturn = Loadable(
  lazy(() => import("./pages/deliveries/DeliveriesReturn"))
);
const DeliveriesSendUpload = Loadable(
  lazy(() => import("./pages/deliveries/DeliveriesSendUpload"))
);
const DeliveriesReturnUpload = Loadable(
  lazy(() => import("./pages/deliveries/DeliveriesReturnUpload"))
);

const DeliveriesReturnFailedUpload = Loadable(
  lazy(() => import("./pages/deliveries/DeliveriesReturnFailedUpload"))
);
const Holiday = Loadable(lazy(() => import("./pages/deliveries/Holiday")));
const DepositWithdrawals = Loadable(
  lazy(() => import("./pages/deposits/DepositWithdrawals"))
);
const AdminList = Loadable(lazy(() => import("./pages/admin/AdminList")));
const AdminEdit = Loadable(lazy(() => import("./pages/admin/AdminEdit")));
const CommuteList = Loadable(lazy(() => import("./pages/admin/CommuteList")));
const NoticeList = Loadable(lazy(() => import("./pages/admin/NoticeList")));
const NoticeEdit = Loadable(lazy(() => import("./pages/admin/NoticeEdit")));
const BannerList = Loadable(lazy(() => import("./pages/marketing/BannerList")));
const EventPages = Loadable(lazy(() => import("./pages/marketing/EventPages")));
const EventPageEdit = Loadable(
  lazy(() => import("./pages/marketing/EventPageEdit"))
);
const EventPushes = Loadable(
  lazy(() => import("./pages/marketing/EventPushes"))
);
const EventPushEdit = Loadable(
  lazy(() => import("./pages/marketing/EventPushEdit"))
);
const EventPoints = Loadable(
  lazy(() => import("./pages/marketing/EventPoints"))
);
const EventPointEdit = Loadable(
  lazy(() => import("./pages/marketing/EventPointEdit"))
);
const BannerEdit = Loadable(lazy(() => import("./pages/marketing/BannerEdit")));
const Promotions = Loadable(lazy(() => import("./pages/marketing/Promotions")));
const PromotionEdit = Loadable(
  lazy(() => import("./pages/marketing/PromotionEdit"))
);
const ConsignmentList = Loadable(
  lazy(() => import("./pages/consignments/ConsignmentList"))
);
const ConsignmentDetail = Loadable(
  lazy(() => import("./pages/consignments/ConsignmentDetail"))
);
const WebBannerList = Loadable(
  lazy(() => import("./pages/marketing/WebBannerList"))
);
const WebBannerEdit = Loadable(
  lazy(() => import("./pages/marketing/WebBannerEdit"))
);
const ConsignmentEventList = Loadable(
  lazy(() => import("./pages/consignments/ConsignmentEventList"))
);
const OverdueOrderList = Loadable(
  lazy(() => import("./pages/orders/OverdueOrderList"))
);
const Coupons = Loadable(lazy(() => import("./pages/marketing/Coupons")));
const CouponEdit = Loadable(lazy(() => import("./pages/marketing/CouponEdit")));
const CouponsUsedLibraryList = Loadable(
  lazy(() => import("./pages/marketing/CouponsUsedLibraryList"))
);
const TagAuthorList = Loadable(
  lazy(() => import("./pages/tag/author/TagAuthorList"))
);
const BookStoreRequestList = Loadable(
  lazy(() => import("./pages/books/BookStoreRequestList"))
);
const BookRecommendList = Loadable(
  lazy(() => import("./pages/books/BookRecommendList"))
);
const BookRecommendDetail = Loadable(
  lazy(() => import("./pages/books/BookRecommendDetail"))
);
const BookNeedStoreList = Loadable(
  lazy(() => import("./pages/books/BookNeedStoreList"))
);
const ConsignmentRestrictionBookList = Loadable(
  lazy(() => import("./pages/books/ConsignmentRestrictionBookList"))
);
const GetKyoboId = Loadable(lazy(() => import("./pages/books/GetKyoboId")));
const OrderedFakeStockList = Loadable(
  lazy(() => import("./pages/orders/OrderedFakeStockList"))
);
const BookHashTagList = Loadable(
  lazy(() => import("./pages/books/BookHashTagList"))
);
const ContentReadReviewList = Loadable(
  lazy(() => import("./pages/books/ContentReadReviewList"))
);
const OrderNotCollected = Loadable(
  lazy(() => import("./pages/orders/OrderNotCollected"))
);
const DeliveriesDivision = Loadable(
  lazy(() => import("./pages/deliveries/DeliveriesDivision"))
);
const StockWoodoSectionCodeUpload = Loadable(
  lazy(() => import("./pages/stocks/StockWoodoSectionCodeUpload"))
);
const DepositRatioManagement = Loadable(
  lazy(() => import("./pages/deposits/DepositRatioManagement"))
);
const CertificationHistoryList = Loadable(
  lazy(() => import("./pages/libraries/CertificationHistoryList"))
);
const RaffleEventList = Loadable(
  lazy(() => import("./pages/marketing/RaffleEventList"))
);
const RaffleEventEdit = Loadable(
  lazy(() => import("./pages/marketing/RaffleEventEdit"))
);
const SchoolRecommendedBookRequestList = Loadable(
  lazy(() => import("./pages/books/SchoolRecommendedBookRequestList"))
);
const WoodoCodeGenerate = Loadable(
  lazy(() => import("./pages/stocks/WoodoCodeGenerate"))
);
const PurchaseOrderNotCollected = Loadable(
  lazy(() => import("./pages/orders/PurchaseOrderNotCollected"))
);
const NonmemberOrderList = Loadable(
  lazy(() => import("./pages/orders/NonmemberOrderList"))
);
const NonmemberOrderDetail = Loadable(
  lazy(() => import("./pages/orders/NonmemberOrderDetail"))
);
const NonmemberOrderEdit = Loadable(
  lazy(() => import("./pages/orders/NonmemberOrderEdit"))
);
const DeliveryEventList = Loadable(
  lazy(() => import("./pages/orders/DeliveryEventList"))
);
const DeliveryEventEdit = Loadable(
  lazy(() => import("./pages/orders/DeliveryEventEdit"))
);
const RegularCashPaymentManagementList = Loadable(
  lazy(() => import("./pages/libraries/RegularCashPaymentManagementList"))
);
const RegularCashPaymentManagementEdit = Loadable(
  lazy(() => import("./pages/libraries/RegularCashPaymentManagementEdit"))
);
const PartnerLibraryBannerList = Loadable(
  lazy(() => import("./pages/marketing/PartnerLibraryBannerList"))
);
const PartnerLibraryBannerEdit = Loadable(
  lazy(() => import("./pages/marketing/PartnerLibraryBannerEdit"))
);
const AffiliatedCompanyList = Loadable(
  lazy(() => import("./pages/admin/AffiliatedCompanyList"))
);
const AffiliatedCompanyEdit = Loadable(
  lazy(() => import("./pages/admin/AffiliatedCompanyEdit"))
);
const ExternalCouponList = Loadable(
  lazy(() => import("./pages/marketing/ExternalCouponList"))
);
const ExternalOrderList = Loadable(
  lazy(() => import("./pages/orders/ExternalOrderList"))
);
const ExternalOrderDetail = Loadable(
  lazy(() => import("./pages/orders/ExternalOrderDetail"))
);
const ExternalOrderEdit = Loadable(
  lazy(() => import("./pages/orders/ExternalOrderEdit"))
);
const ExternalOrderProcess = Loadable(
  lazy(() => import("./pages/orders/ExternalOrderProcess"))
);
const StockSellableEdit = Loadable(
  lazy(() => import("./pages/stocks/StockSellableEdit"))
);
const ReadingNotesList = Loadable(
  lazy(() => import("./pages/books/ReadingNotesList"))
);
const BookReturnRequestList = Loadable(
  lazy(() => import("./pages/consignments/ReturnRequestList"))
);
const BookReturnRequestDetail = Loadable(
  lazy(() => import("./pages/consignments/ReturnRequestDetail"))
);
const BookReturnRequestEdit = Loadable(
  lazy(() => import("./pages/consignments/ReturnRequestEdit"))
);
const ExternalStockList = Loadable(
  lazy(() => import("./pages/stocks/ExternalStockList"))
);
const ExternalExpectStoreBookList = Loadable(
  lazy(() => import("./pages/orders/ExternalExpectStoreBookList"))
);
const BookStoreProcess = Loadable(
  lazy(() => import("./pages/books/BookStoreProcess"))
);
const BookStoreList = Loadable(
  lazy(() => import("./pages/books/BookStoreList"))
);
const BookStoreDetail = Loadable(
  lazy(() => import("./pages/books/BookStoreDetail"))
);

// mvp
const ProductList = Loadable(lazy(() => import("./pages/mvp/ProductList")));
const ProductEdit = Loadable(lazy(() => import("./pages/mvp/ProductEdit")));
const MvpOrderList = Loadable(lazy(() => import("./pages/mvp/MvpOrderList")));
const MvpOrderDetail = Loadable(
  lazy(() => import("./pages/mvp/MvpOrderDetail"))
);
const MvpOrderEdit = Loadable(lazy(() => import("./pages/mvp/MvpOrderEdit")));
const MvpUsedStockList = Loadable(
  lazy(() => import("./pages/mvp/MvpUsedStockList"))
);
const MvpUsedStockDetail = Loadable(
  lazy(() => import("./pages/mvp/MvpUsedStockDetail"))
);
const MvpUsedStockEdit = Loadable(
  lazy(() => import("./pages/mvp/MvpUsedStockEdit"))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.authData) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/", element: <Overview /> },
      {
        path: "libraries",
        children: [
          { path: "", element: <LibraryList /> },
          { path: ":libraryId/*", element: <LibraryDetail /> },
          { path: ":libraryId/edit", element: <LibraryEdit /> },
          {
            path: "cart/recent-register",
            element: <CartRecentRegisterLibraryList />,
          },
          {
            path: "certification/history",
            element: <CertificationHistoryList />,
          },
          {
            path: "regular-cash-payment-management",
            element: <RegularCashPaymentManagementList />,
          },
          {
            path: "regular-cash-payment-management/:regularCashPaymentManagementId/edit",
            element: <RegularCashPaymentManagementEdit />,
          },
        ],
      },
      {
        path: "home",
        children: [
          { path: "", element: <HomeTagList /> },
          { path: "hometag", element: <HomeTagList /> },
          { path: "hometag/:tagId", element: <HomeTagEdit /> },
        ],
      },
      {
        path: "tag",
        children: [
          { path: "", element: <TagManagedList /> },
          { path: "tagmanaged", element: <TagManagedList /> },
          { path: "tagmanaged/:tagId", element: <TagManagedEdit /> },
          { path: "tagcustom", element: <TagCustomList /> },
          { path: "tagcustom/:tagId", element: <TagCustomEdit /> },
          { path: "tagevents", element: <TagEventsList /> },
          { path: "tagevents/:tagId", element: <TagEventsEdit /> },
          { path: "tagseries", element: <TagSeriesList /> },
          { path: "tagseries/:tagId", element: <TagEventsEdit /> },
          { path: "tagauthor", element: <TagAuthorList /> },
          { path: "tagauthor/:tagId", element: <TagEventsEdit /> },
        ],
      },
      {
        path: "books",
        children: [
          { path: "", element: <BookList /> },
          { path: ":bookId/edit", element: <BookEdit /> },
          { path: "add", element: <BookEdit add /> },
          { path: "tags", element: <TagList /> },
          { path: "tags/:tagId", element: <TagEdit /> },
          {
            path: "categories/change-request",
            element: <CategoryChangeRequestList />,
          },
          { path: "excel-search", element: <BookExcelSearchList /> },
          {
            path: "consignment-available/search",
            element: <ConsignmentAvailableBookList />,
          },
          {
            path: "consignment-restriction/search",
            element: <ConsignmentRestrictionBookList />,
          },
          { path: "book-store-request", element: <BookStoreRequestList /> },
          { path: "book-recommend", element: <BookRecommendList /> },
          {
            path: "book-recommend/:recommendId",
            element: <BookRecommendDetail />,
          },
          { path: "need-store", element: <BookNeedStoreList /> },
          { path: "get-kyoboid", element: <GetKyoboId /> },
          {
            path: "school-recommended-request",
            element: <SchoolRecommendedBookRequestList />,
          },
          { path: "book-store-process", element: <BookStoreProcess /> },
          { path: "book-store", element: <BookStoreList /> },
          { path: "book-store-detail/:date", element: <BookStoreDetail /> },
        ],
      },
      {
        path: "contents",
        children: [
          { path: "content-read-review", element: <ContentReadReviewList /> },
          { path: "reading-notes", element: <ReadingNotesList /> },
        ],
      },
      {
        path: "book-hash-tag",
        children: [{ path: "", element: <BookHashTagList /> }],
      },
      {
        path: "stocks",
        children: [
          { path: "", element: <StockList /> },
          { path: ":stockId/*", element: <StockDetail /> },
          { path: ":stockId/edit", element: <StockEdit /> },
          {
            path: "woodo-section-code-upload",
            element: <StockWoodoSectionCodeUpload />,
          },
          { path: "woodo-code-generate", element: <WoodoCodeGenerate /> },
          { path: "edit-sellable", element: <StockSellableEdit /> },
          { path: "external-stocks", element: <ExternalStockList /> },
        ],
      },
      {
        path: "consignments",
        children: [
          { path: "", element: <ConsignmentList /> },
          {
            path: ":bookConsignmentRegistrationId",
            element: <ConsignmentDetail />,
          },
          { path: "event", element: <ConsignmentEventList /> },
          { path: "return-requests", element: <BookReturnRequestList /> },
          {
            path: "return-requests/:bookReturnRequestId",
            element: <BookReturnRequestDetail />,
          },
          {
            path: "return-requests/:bookReturnRequestId/edit",
            element: <BookReturnRequestEdit />,
          },
        ],
      },
      {
        path: "",
      },
      {
        path: "orders",
        children: [
          { path: "", element: <OrderList /> },
          { path: ":orderId/*", element: <OrderDetail /> },
          { path: ":orderId/edit", element: <OrderEdit /> },
          {
            path: "ordered-fake-stock/list",
            element: <OrderedFakeStockList />,
          },
          { path: "ordernotcollected", element: <OrderNotCollected /> },
          {
            path: "purchase-orders-not-collected",
            element: <PurchaseOrderNotCollected />,
          },
          { path: "nonmember-orders", element: <NonmemberOrderList /> },
          {
            path: "nonmember-orders/:nonmemberOrderId/*",
            element: <NonmemberOrderDetail />,
          },
          {
            path: "nonmember-orders/:nonmemberOrderId/edit",
            element: <NonmemberOrderEdit />,
          },
          { path: "delivery-events", element: <DeliveryEventList /> },
          {
            path: "delivery-events/:deliveryEventId/edit",
            element: <DeliveryEventEdit add={false} />,
          },
          {
            path: "delivery-events/add",
            element: <DeliveryEventEdit add={true} />,
          },
          { path: "external-orders", element: <ExternalOrderList /> },
          {
            path: "external-orders/:externalOrderId/*",
            element: <ExternalOrderDetail />,
          },
          {
            path: "external-orders/:externalOrderId/edit",
            element: <ExternalOrderEdit />,
          },
          { path: "external-order-process", element: <ExternalOrderProcess /> },
          {
            path: "external-expect-store-books",
            element: <ExternalExpectStoreBookList />,
          },
        ],
      },
      {
        path: "overdue-orders",
        children: [{ path: "", element: <OverdueOrderList /> }],
      },
      {
        path: "deliveries",
        children: [
          { path: "send", element: <DeliveriesSend /> },
          { path: "send/upload", element: <DeliveriesSendUpload /> },
          { path: "return", element: <DeliveriesReturn /> },
          { path: "return/upload", element: <DeliveriesReturnUpload /> },
          {
            path: "returnFailed/upload",
            element: <DeliveriesReturnFailedUpload />,
          },
          { path: "holiday", element: <Holiday /> },
          { path: "division", element: <DeliveriesDivision /> },
        ],
      },
      {
        path: "deposits",
        children: [
          { path: "withdrawals", element: <DepositWithdrawals /> },
          { path: "ratio/management", element: <DepositRatioManagement /> },
        ],
      },
      {
        path: "marketing",
        children: [
          {
            path: "eventPages",
            children: [
              { path: "", element: <EventPages /> },
              { path: ":eventPageId/edit", element: <EventPageEdit /> },
              { path: "add", element: <EventPageEdit add /> },
            ],
          },
          {
            path: "eventPushes",
            children: [
              { path: "", element: <EventPushes /> },
              { path: ":eventPushId/edit", element: <EventPushEdit /> },
              { path: "add", element: <EventPushEdit add /> },
            ],
          },
          {
            path: "eventPoints",
            children: [
              { path: "", element: <EventPoints /> },
              { path: ":eventPointId/edit", element: <EventPointEdit /> },
              { path: "add", element: <EventPointEdit add /> },
            ],
          },
          {
            path: "banners",
            children: [
              { path: "", element: <BannerList /> },
              { path: ":bannerId/edit", element: <BannerEdit /> },
              { path: "add", element: <BannerEdit add /> },
            ],
          },
          {
            path: "web-banners",
            children: [
              { path: "", element: <WebBannerList /> },
              {
                path: ":webBannerId/edit",
                element: <WebBannerEdit add={false} />,
              },
              { path: "add", element: <WebBannerEdit add={true} /> },
            ],
          },
          {
            path: "partner-library-banners",
            children: [
              { path: "", element: <PartnerLibraryBannerList /> },
              {
                path: ":partnerLibraryBannerId/edit",
                element: <PartnerLibraryBannerEdit add={false} />,
              },
              { path: "add", element: <PartnerLibraryBannerEdit add={true} /> },
            ],
          },
          {
            path: "promotions",
            children: [
              { path: "", element: <Promotions /> },
              {
                path: ":promotionId/edit",
                element: <PromotionEdit add={false} />,
              },
              { path: "add", element: <PromotionEdit add={true} /> },
            ],
          },
          {
            path: "coupons",
            children: [
              { path: "", element: <Coupons /> },
              { path: ":couponId/edit", element: <CouponEdit add={false} /> },
              { path: "add", element: <CouponEdit add={true} /> },
              {
                path: ":couponId/used-libraries",
                element: <CouponsUsedLibraryList />,
              },
            ],
          },
          {
            path: "external-coupons",
            children: [{ path: "", element: <ExternalCouponList /> }],
          },
          {
            path: "raffle-events",
            children: [
              { path: "", element: <RaffleEventList /> },
              {
                path: ":raffleEventId/edit",
                element: <RaffleEventEdit add={false} />,
              },
              { path: "add", element: <RaffleEventEdit add={true} /> },
            ],
          },
        ],
      },
      {
        path: "admin",
        children: [
          {
            path: "notices",
            children: [
              { path: "", element: <NoticeList /> },
              { path: ":noticeId/edit", element: <NoticeEdit /> },
              { path: "add", element: <NoticeEdit add /> },
            ],
          },
          {
            path: "admins",
            children: [
              { path: "", element: <AdminList /> },
              { path: ":adminId/edit", element: <AdminEdit /> },
              { path: "add", element: <AdminEdit add /> },
            ],
          },
          { path: "commutes", element: <CommuteList /> },
          {
            path: "affiliated-company",
            children: [
              { path: "", element: <AffiliatedCompanyList /> },
              {
                path: ":affiliatedCompanyId/edit",
                element: <AffiliatedCompanyEdit />,
              },
            ],
          },
        ],
      },
      {
        path: "mvp",
        children: [
          {
            path: "products",
            children: [
              { path: "", element: <ProductList /> },
              { path: ":productId/edit", element: <ProductEdit /> },
              { path: "add", element: <ProductEdit add /> },
            ],
          },
          {
            path: "used-stocks",
            children: [
              { path: "", element: <MvpUsedStockList /> },
              { path: ":usedStockId/*", element: <MvpUsedStockDetail /> },
              { path: ":usedStockId/edit", element: <MvpUsedStockEdit /> },
              { path: "add", element: <MvpUsedStockEdit add /> },
            ],
          },
          {
            path: "orders",
            children: [
              { path: "", element: <MvpOrderList /> },
              { path: ":orderId/*", element: <MvpOrderDetail /> },
              { path: ":orderId/edit", element: <MvpOrderEdit /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
